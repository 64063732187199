import _get from 'lodash/get';
import _forOwn from 'lodash/forOwn';
import _isArray from 'lodash/isArray';
import _isNumber from 'lodash/isNumber';
import _isNil from 'lodash/isNil';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _intersection from 'lodash/intersection';
import _capitalize from 'lodash/capitalize';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import _toNumber from 'lodash/toNumber';
import _isString from 'lodash/isString';
import _cloneDeep from 'lodash/cloneDeep';
import { Calculator, fmsUtils } from '@web/tesla-rest-ds-services';
import {
  i18n,
  getOptionPricing,
  findMatrixValue,
  constructUrl,
  calculateGeoDistance,
  parseSelectedBy,
  getKeyByValue,
  htmlToReact,
  matchesTargetValue,
  matchAgainstEveryKey,
} from 'utils';
import {
  formatNumber,
  formatCurrency,
  formatMonthlyPrice,
  formatPercent,
  filterCollection,
} from '@tesla/coin-common-components';
import {
  FinanceTypes,
  PRICING_ORDER_PAYMENT,
  REGISTRATION_TYPE_PRIVATE,
  REGISTRATION_TYPE_BUSINESS,
  FOUNDERS,
  PRICING_ORDER_FEE,
  NON_REFUNDABLE_ORDER_PAYMENT,
  DELIVERY_SOURCE_TRT,
  DELIVERY_SOURCE_VRL,
  ORDER_FEE_TYPE,
  NON_REFUNDABLE_ORDER_PAYMENT_TYPE,
  NON_REFUNDABLE_ORDER_PAYMENT_USED,
  PRICING_ORDER_FEE_REFUNDABLE_ADDITIVE,
  ORDER_FEE_REFUNDABLE_ADDITIVE_TYPE,
  CONTEXT_DEFAULT,
  AUTO_LEASE,
  PRODUCT_TYPE,
  TESLA_FEE,
  FEE_CODES,
  NON_TESLA_FEE,
  AUTO_LOAN,
  Models,
  MODEL_OPTION,
  LEASING_STATES_LIST,
  SET_FINANCE_MODAL_TAB_TRADEIN,
  CUSTOMER_TYPES,
  DEFAULT_FIN_TABS,
  EXPERIMENT_TOP_BANNER_OFF,
} from 'dictionary';
import { formatDate } from '@tesla/intl-date-time';
import { getModelCode, getSelectedUpgrades, getTrimCode, isInventory, isUsedInventory } from './common';
import {
  getModel,
  isEnterpriseOrder,
  isReservationOrder,
  getTradeInType,
  getPricingContext,
  getModelName,
  getUpgradesHaveConditionalPricing,
  getUserRegionName,
  getUserRegionCode,
} from 'selectors';
import _head from 'lodash/head';

const { getMaxLeaseTerm, getMaxLeaseDistance } = fmsUtils || {};

/**
 * return number of years the gas savings calculation is based on
 */
export function getFuelSavings(state) {
  const tccFuel = _get(state, 'Financial.tcc.current.fuel[0]', null);

  if (tccFuel) {
    return Math.abs(_get(tccFuel, 'savingsAmount', 0));
  }

  return Math.abs(_get(state.Pricing, 'calculatorResult.data.variables.fuelSavings.total'));
}

export function getIncentivesTotal(state, params = { includeFuel: false, includeSavings: false }) {
  const {
    includeFuel = false,
    includeAllCurrentIncentives,
    absoluteValue,
    includeSavings = false,
    incentivesInPurchasePrice = false,
    doFormatCurrency = true,
  } = params;
  const excludeKeys = ['monthly', 'included', 'includedInPurchasePrice', 'fuel', 'credit'];
  const incentiveAmounts = includeAllCurrentIncentives
    ? _get(state, 'Financial.incentives.current', {})
    : _get(state, 'Financial.incentives.total', {});

  const flagForFees = _get(state, 'FinancingOptions.addFuelFees', false);

  let total = 0;
  let fuelSavings = 0;

  _forOwn(incentiveAmounts, (val, key) => {
    const excludeFuelFeels = flagForFees
      ? includeAllCurrentIncentives
      : includeAllCurrentIncentives && key !== 'fuel';
    if (excludeFuelFeels) {
      const period = _get(val, '[0].period');
      if (['includedInPurchasePrice', 'included'].indexOf(period) === -1) {
        val.map(itm => {
          total += _get(itm, 'amount', 0);
        });
      }
    } else if (!excludeKeys.includes(key)) {
      total += val;
    }
  });

  if (includeFuel) {
    fuelSavings = getFuelSavings(state);
  }

  if (includeSavings) {
    const savingsAmount = _get(state, 'Financial.tcc.savingsTotal.once', 0);

    total += -1 * (savingsAmount - fuelSavings);
  }

  if (absoluteValue) {
    total = Math.abs(total - fuelSavings);
  }

  if (incentivesInPurchasePrice) {
    total += Math.abs(_get(state, 'Financial.incentives.total.includedInPurchasePrice', 0));
  }

  const formattedTotal = total ? formatCurrency(total) : null;

  return doFormatCurrency ? formattedTotal : total;
}

export function hasPersistentIncentives(state) {
  const incentives = _get(state, 'FinancingOptions.persistentIncentives');
  return _isArray(incentives) && !!incentives.length;
}

export function singularize(str) {
  return str.slice(-1) === 's' ? str.slice(0, -1) : str;
}

export function getApplicableIncentiveOptions(state, filter = {}, type = 'incentives') {
  const singularizedType = singularize(type);
  const incentiveType = _get(filter, `${singularizedType}Type`);
  const selectedIncentiveId = _get(state, `SummaryPanel.${incentiveType}`, 'other');
  const current = _get(state, `Financial.${type}.current`, {});
  const currentFilter = _omit({ ...filter }, 'componentType');

  const incentives = Object.keys(current).reduce((r, k) => {
    current[k].forEach(incentive => {
      if (incentive.options && _isArray(incentive.options)) {
        incentive.options.forEach(incentiveOption => {
          r.push({
            ...incentiveOption,
            selected: incentiveOption.id === selectedIncentiveId,
            [`${singularizedType}Type`]: incentive[`${singularizedType}Type`],
            amount: incentiveOption.amount || incentive.amount,
            period: incentive.period,
          });
        });
      } else {
        r.push(incentive);
      }
    });
    return r;
  }, []);
  return filterCollection(incentives, { ...currentFilter });
}

export function getOtherVisibleFees(state) {
  const allFees = _get(state.Financial, 'fees.fees', {});
  const otherFees = _get(state.FinancingOptions, 'otherFees', []);
  return otherFees.map(fee => {
    if (allFees[fee.feeType]) {
      const amount = _get(allFees[fee.feeType], '[0].amount', 0);
      return { amount, ...fee, label: i18n(fee.label) };
    }
    return null;
  });
}

/**
 * return number of months the gas savings calculation is based on
 */
export function getFuelMonths(state) {
  const defaultMonths = _get(
    state,
    'Financial.fms_tcc.tcc[0].variables.months',
    _get(state, 'Financial.fms_incentives.incentives[0].variables.months')
  );
  const numMonths = _get(
    state,
    'Financial.tcc.currentAvailable.fuel[0].variables.months',
    _get(state, 'Financial.incentives.currentAvailable.fuel[0].variables.months', defaultMonths)
  );
  return numMonths;
}

/**
 * return number of years the gas savings calculation is based on
 */
export function getFuelYears(state) {
  const numMonths = getFuelMonths(state);
  return numMonths / 12;
}

/**
 * Get currently selected finance product.
 * Possible values (not exhaustive):
 *   loan, lease, loan.something, lease.operational_lease, lease.lease.
 *
 * @return {String}
 */
export function getSelectedFinanceProduct(state, { short = false } = {}) {
  const { selected_tab: selectedTab } = state.SummaryPanel;

  if (!getActiveFinanceProducts(state).includes(selectedTab)) {
    return (
      selectedTab === FinanceTypes.CASH_BUSINESS ?
        FinanceTypes.CASH_BUSINESS :
        FinanceTypes.CASH
    );
  }

  return short ? `${selectedTab}`.split(':')[0] : selectedTab;
}

/**
 * Assuming we have a selected tab of 'lease.operational_lease', we might
 * want just the 'operational_lease' part to send to financial services.
 *
 * @param state
 * @param selectedProduct If not taken from state, we can pass the product name in this argument.
 * @returns {String|string}
 */
export function getFinanceProductId(state, selectedProduct = null) {
  const useProduct = selectedProduct || getSelectedFinanceProduct(state) || [];
  const prefixed = [FinanceTypes.LEASE, FinanceTypes.LOAN, FinanceTypes.FINPLAT];

  for (const prefix of prefixed) {
    if (useProduct.indexOf(`${prefix}.`) === 0) {
      return useProduct.substr(prefix.length + 1);
    }
  }
  if (useProduct === FinanceTypes.LEASE || useProduct === FinanceTypes.LOAN) {
    return useProduct;
  }

  return null;
}

/**
 * Get currently selected finance type.
 * Possible values (exhaustive): cash, loan, lease, finplat
 * @return {String}
 */
export function getFinanceType(state, productName = null) {
  const selectedProduct = productName || getSelectedFinanceProduct(state);

  return [
    FinanceTypes.LEASE,
    FinanceTypes.LOAN,
    FinanceTypes.FINPLAT
  ].find(type => selectedProduct.includes(type)) || FinanceTypes.CASH
}

/**
 * Fetch the config belonging to the currently active finance product or a specific product.
 * This will not assume the first entry, but takes the productId into account.
 * It will fallback to the first entry if no product is found.
 *
 * @param state
 * @param financeTabId
 * @param useFinanceType
 * @returns {*|{}}
 */
export function getFinanceProductData(state, financeTabId = null, useFinanceType = null) {
  const financeProductId = getFinanceProductId(state, financeTabId);
  const financeType = useFinanceType || getFinanceType(state);

  if (financeType === FinanceTypes.FINPLAT) {
    return _get(state, `Financial.fms_finplat[${financeProductId}]`, {});
  }

  const allData = _get(state, `Financial.fms_${financeType}.${financeType}`, []);
  const productFoundById =
    financeProductId && allData.find(currentData => currentData.id === financeProductId);

  return productFoundById || allData[0] || {};
}

/**
 * Fetch all lease and loan products loaded.
 *
 * @param state
 * @param onlyActive will filter out non-active products
 * @returns Array of products
 */

export function getFinanceProductsData(state, onlyActive = true) {
  const activeFinanceProducts = getActiveFinanceProducts(state);

  return [FinanceTypes.LEASE, FinanceTypes.LOAN].reduce((acc, financeType) => {
    const allData = [...(state?.Financial?.[`fms_${financeType}`]?.[financeType] ?? []), ...acc];

    if (!onlyActive) {
      return allData;
    }

    return allData.filter(product => {
      const financeProductId = [product.productType, product.id].filter(Boolean).join('.');
      return activeFinanceProducts.includes(financeProductId);
    });
  }, []);
}

/**
 * Check if the currently selected financeType has a distance field.
 * We might want to use this logic to pass on to the Incentives API for
 * fuel savings calculations purposes.
 * @param state
 * @return boolean
 */
export function hasDistanceField(state) {
  const financeType = getFinanceType(state);
  const formFields = {
    [FinanceTypes.LEASE]: _flatten(_get(state, 'Forms.showLeaseFields', [])),
    [FinanceTypes.LOAN]: _flatten(_get(state, 'Forms.showLoanFields', [])),
  };

  switch (financeType) {
    case FinanceTypes.LEASE:
      return formFields[financeType].indexOf('distance') >= 0;

    case FinanceTypes.LOAN:
      return formFields[financeType].indexOf('loanDistance') >= 0;

    case FinanceTypes.FINPLAT: {
      const financeProductId = getFinanceProductId(state);
      const finplatFields = getFinplatFormFields(state, financeProductId);
      if (finplatFields.includes('distanceAllowed')) {
        return true;
      } else if (finplatFields.length === 0) {
        const termOptions = state.Pricing.finplat?.output?.outputs?.termOptions ?? {};
        const availableDistances = Array.from(
          new Set(
            Object.values(termOptions)
              .map(({ distanceAllowed }) => distanceAllowed)
              .filter(Boolean)
          )
        );
        return availableDistances?.length > 0;
      }
    }

    default:
      return false;
  }
}

/**
 * Generic way to pull showLeaseSummaryFields /  showLoanSummaryFields from either state or finance product
 */
export function getShowSummaryFields(state, financeType) {
  if ([FinanceTypes.LEASE, FinanceTypes.LOAN].indexOf(financeType) !== -1) {
    const type = _capitalize(financeType);
    const financeProductData = getFinanceProductData(state);
    const showSummaryFields = _get(
      financeProductData,
      `uiSettings.reviewDetails.show${type}SummaryFields`
    );
    if (showSummaryFields) {
      return showSummaryFields;
    }
    return _get(state, `ReviewDetails.show${type}SummaryFields`, []);
  }
  return [];
}

/**
 * Generic way to pull showLeaseSummaryFields from either state or finance product
 */
export function getShowLeaseSummaryDisclaimer(state) {
  const showLeaseSummaryDisclaimer = _get(state, 'ReviewDetails.showLeaseSummaryDisclaimer', false);

  if (Array.isArray(showLeaseSummaryDisclaimer)) {
    const financeTypeUsed = getFinanceType(state);
    const financeProductIdUsed = getFinanceProductId(state);
    return (
      showLeaseSummaryDisclaimer.includes(financeTypeUsed) ||
      showLeaseSummaryDisclaimer.includes(`${financeTypeUsed}.${financeProductIdUsed}`)
    );
  }

  return showLeaseSummaryDisclaimer;
}

/**
 * Generic way to pull a variable from the active finance product or a specific product.
 *
 * @param state
 * @param variable
 * @param financeTabId
 * @returns {*}
 */
export function getFinanceProductVariable(state, variable, financeTabId = null) {
  const productData = getFinanceProductData(state, financeTabId);

  return _get(productData, `variables.${variable}`, null);
}

export function getFinanceProductDistancesOffered(state, financeTabId = null) {
  const productData = getFinanceProductData(state, financeTabId);
  const financeType = getFinanceType(state);

  if (financeType === FinanceTypes.LEASE) {
    const leaseTermMileages = _get(productData, 'leaseTermsOffered.leaseTermMileages');

    if (leaseTermMileages && Object.keys(leaseTermMileages).length > 0) {
      // eslint-disable-next-line no-use-before-define
      const term = getLeaseTerm(state, financeTabId);
      const productConfig = _get(state, 'Financial.fms_lease.lease[0]', {});
      const Odometer = _get(state, 'ReviewDetails.product.data.Odometer', null);
      const maxLeaseDistance = getMaxLeaseDistance(productConfig, Odometer, term);
      const mileages = leaseTermMileages[term] || [];
      return mileages.filter(distance => distance <= maxLeaseDistance) || [];
    }

    return _get(productData, 'leaseTermsOffered.leaseDistance', []);
  }

  if (financeType === FinanceTypes.LOAN) {
    return _get(productData, 'loanTermsOffered.loanDistance', []);
  }

  return [];
}

export function getFinanceProductTermsOffered(state, financeTabId = null) {
  const productData = getFinanceProductData(state, financeTabId);
  const financeType = getFinanceType(state);

  if (financeType === FinanceTypes.LEASE) {
    const productConfig = _get(state, 'Financial.fms_lease.lease[0]', {});
    const Odometer = _get(state, 'ReviewDetails.product.data.Odometer', null);
    const ltvMaxTerm = state.Pricing?.lease?.maximumTerm;
    const registrationAge = getRegistrationAge(state);
    const maxLeaseTerm = getMaxLeaseTerm(productConfig, Odometer, registrationAge, ltvMaxTerm);
    return _get(productData, 'leaseTermsOffered.leaseTerm', []).filter(
      term => term <= maxLeaseTerm
    );
  }

  if (financeType === FinanceTypes.LOAN) {
    return _get(productData, 'loanTermsOffered.leaseTerm', []);
  }

  if (financeType === FinanceTypes.FINPLAT) {
    return productData?.parameters?.availableTerms || [];
  }

  return [];
}

export function getLeaseMileage(state, financeTabId = null) {
  const mileage = _get(state, 'SummaryPanel.leaseDistance');
  const distancesOffered = getFinanceProductDistancesOffered(state, financeTabId);
  const defaultMileage =
    getFinanceProductVariable(state, `leaseDistance__${getModel(state)}`, financeTabId) ||
    getFinanceProductVariable(state, 'leaseDistance', financeTabId);

  if (mileage != null && distancesOffered.includes(mileage)) {
    return mileage;
  }

  if (defaultMileage != null && distancesOffered.includes(defaultMileage)) {
    return defaultMileage;
  }

  return distancesOffered[0];
}

export function getLoanDistance(state) {
  const mileage = _get(state, 'SummaryPanel.loanDistance');
  const distancesOffered = getFinanceProductDistancesOffered(state);

  if (mileage != null && distancesOffered.includes(mileage)) {
    return mileage;
  }

  return getFinanceProductVariable(state, 'loanDistance');
}

export function getFinplatDistance(state) {
  return _get(state, 'Pricing.finplat.output.inputs.distanceAllowed', null);
}

/**
 * Returns the miles input or default value for Gas Savings.
 * @param state
 * @returns {*} yearly amount of miles/km driven.
 */
export function getFuelDistance(state) {
  const financeType = getFinanceType(state);
  const years = getFuelYears(state);
  const defaultMileage =
    _get(state, 'Financial.fms_incentives.incentives[0].variables.distance_year') ||
    _get(state, 'Financial.fms_incentives.incentives[0].variables.distance', 0) / years;
  const lastCalculated =
    _get(state, 'Financial.incentives.current.fuel[0].variables.distance', 0) / years;
  const userInput = _get(state, 'SummaryPanel.fuelDistPer', null);

  const fmsTcc = _get(state, 'Financial.fms_tcc.tcc', []);
  const tccFuel = fmsTcc.find(tcc => tcc.tccType === 'fuel');
  const tccFuelDistance = _get(tccFuel, 'variables.mileage', null);
  const finplatDistance = getFinplatDistance(state);

  if (financeType === FinanceTypes.LEASE && hasDistanceField(state)) {
    return Math.ceil(getLeaseMileage(state) || 0);
  }

  if (financeType === FinanceTypes.LOAN && hasDistanceField(state)) {
    return Math.ceil(getLoanDistance(state) || 0);
  }

  if (financeType === FinanceTypes.FINPLAT && hasDistanceField(state) && finplatDistance) {
    return Math.ceil(finplatDistance || 0);
  }

  return Math.ceil(userInput || tccFuelDistance || lastCalculated || defaultMileage || 0);
}

/**
 * Grab the fuel price which could be overridden in UI by user.
 * @param state
 * @returns {*}
 */
export function getFuelPrice(state) {
  const defaultPrice = state?.Financial?.fms_incentives?.incentives[0]?.variables?.fuel_price ?? 0;
  const userInput = _get(state, 'SummaryPanel.fuelPrice', null);
  const fmsTcc = _get(state, 'Financial.fms_tcc.tcc', []);
  const tccFuel = fmsTcc.find(tcc => tcc.tccType === 'fuel');
  const tccFuelPrice = _get(tccFuel, 'variables.petrolCost', null);

  return userInput || tccFuelPrice || defaultPrice || 0;
}

/**
 * Grab the fuel price which could be overridden in UI by user.
 * @param state
 * @returns {*}
 */
export function getMaintenanceAmount(state) {
  const userInput = _get(state, 'SummaryPanel.maintenanceAmount', null);

  const fmsTcc = _get(state, 'Financial.fms_tcc.tcc', []);
  const tccMaintenance = fmsTcc.find(tcc => tcc.tccType === 'maintenance');
  const tccMaintenanceAmount = Math.round(_get(tccMaintenance, 'monthlyComparisonAmount', 0) * 12);

  return userInput || tccMaintenanceAmount;
}

/**
 * Grab the fuel efficiency which could be overridden in UI by user.
 * @param state
 * @returns {*}
 */
export function getFuelEfficiency(state) {
  const userInput = _get(state, 'SummaryPanel.fuelEfficiency', null);
  const fuel_efficiency_metric = _get(
    state,
    'Financial.fms_incentives.incentives[0].variables.fuel_efficiency_metric',
    0
  );
  const fuel_efficiency_imperial = _get(
    state,
    'Financial.fms_incentives.incentives[0].variables.fuel_efficiency_imperial',
    0
  );
  return userInput || fuel_efficiency_metric || fuel_efficiency_imperial;
}

/**
 * Grab the electricity price which could be overridden in UI by user.
 * @param state
 * @returns {*}
 */
export function getElectricityPrice(state) {
  const userInput = state?.SummaryPanel?.electricityPrice ?? null;
  const kwh_price = Number(
    (state?.Financial?.fms_incentives?.incentives[0]?.variables?.kwh_price ?? 0).toFixed(2)
  );
  return userInput || kwh_price;
}

/**
 * Determine if we use the term provided by the financing product, or a default value.
 *
 * @param state
 * @returns {boolean}
 */
export function doWeConsiderTermForGas(state) {
  const { showGasSavingsFuelYear } = state?.FinancingOptions || {};
  const currentFinanceType = getFinanceType(state);
  const financeProductId = getFinanceProductId(state);
  const financeProductData = getFinanceProductData(state);
  if (_get(financeProductData, 'variables.ignoreTermForGas', false)) {
    return false;
  }
  return (
    showGasSavingsFuelYear ||
    currentFinanceType === FinanceTypes.LEASE ||
    (currentFinanceType === FinanceTypes.FINPLAT && financeProductId.startsWith('AUTO_LEASE:'))
  );
}

/**
 * Return registration and doc fee
 */
export function getDestinationAndDocFee(state) {
  return _get(state, 'Financial.fees.current.reg_and_doc_fee[0].amount', 0);
}

/**
 * Return fee by ID
 */
export function getFeeAmountById(state, feeId) {
  return _get(state, `Financial.fees.current.${feeId}[0].amount`, 0);
}

/**
 * Return fee by ID
 */
export function getFeeObjById(state, feeId) {
  return _get(state, `Financial.fees.current.${feeId}[0]`, {});
}

/**
 * Return government incentives
 */
export function getGovernmentIncentives(state) {
  const incentives = _get(state, 'Financial.incentives.current.government[0]', {});
  return incentives?.period === 'includedInPurchasePrice' ? incentives?.amount || 0 : 0;
}

/**
 * Return clean vehicle incentives
 */
export function getCleanVehicleIncentives(state) {
  return state?.Financial?.incentives?.current?.cleanVehicle?.[0]?.amount || 0;
}

/**
 * Return regional incentives
 */
export const getRegionalIncentives = state => {
  return state?.Financial?.incentives?.current?.regional?.[0]?.amount || 0;
};

/**
 * Return available regional incentives
 */
 export const getAvailableRegionalIncentives = state => {
  return state?.Financial?.incentives?.currentAvailable?.regional?.[0]?.amount || 0;
};

/**
 * Return regional incentives for base trim
 */
export const getRegionalIncentivesForBase = state => {
  const trims = state?.Pricing?.calculatorResult?.data?.apiResultsPerTrim || {};
  const firstTrim = Object.keys(trims)[0];
  return Math.abs(trims[firstTrim]?.incentives?.current?.regional?.data[0]?.amount || 0) || Math.abs(_get(state, 'Financial.incentives.currentAvailable.regional[0].amount', 0));
};

/**
 * Get Innovation Bonus
 *
 * @param {object} state
 * @returns amount
 */
export const getInnovationBonus = state => {
  return state?.Financial?.incentives?.current?.innovationBonus?.[0]?.amount || 0;
};

/**
 * Get the currently calculated VAT amount.
 *
 * @param state
 * @returns number
 */
export function getVATAmount(state) {
  return _get(state, 'Pricing.calculatorResult.data.variables.fees.vat_percent.total', 0);
}

/**
 * Get the VAT percent.
 *
 * @param state
 * @returns number
 */
export function getVATPercent(state) {
  return _get(state, 'Pricing.calculatorResult.data.variables.fees.vat_percent.data[0].percent', 0);
}

/**
 * Get SCT tax object
 *
 * @param state
 * @returns {Object}
 */
export function getSCTTax(state) {
  return state?.Pricing?.calculatorResult?.data?.variables?.fees?.sct_tax ?? {};
}

/**
 * Returns Reservation Info [payment object]
 * @param state
 * @returns {Object}
 */
export function getReservationAmount(state) {
  const { VehicleDetail, product } = state?.ReviewDetails || {};
  const { data: payment } = product || {};
  const { series = null } = VehicleDetail || {};
  if (series && payment) {
    return series === FOUNDERS ? payment.amount_founder : payment.amount_general || null;
  }
  return null;
}

/**
 * Show price excluding VAT
 */
export function getShowExVat(state, { financeType, financeProductId } = {}) {
  const showMonthlyPaymentExVAT = _get(state, 'FinancingOptions.showMonthlyPaymentExVAT', false);

  if (Array.isArray(showMonthlyPaymentExVAT)) {
    const financeTypeUsed = financeType || getFinanceType(state);
    const financeProductIdUsed = financeProductId || getFinanceProductId(state);
    return (
      showMonthlyPaymentExVAT.includes(financeTypeUsed) ||
      showMonthlyPaymentExVAT.includes(`${financeTypeUsed}.${financeProductIdUsed}`)
    );
  }

  return showMonthlyPaymentExVAT;
}

/**
 * return number of years the gas savings calculation is based on
 */
export function getFuelSavingsPerMonth(state, options = { showAmtWithoutMinus: false }) {
  const value = _get(state.Pricing, 'calculatorResult.data.variables.fuelSavings.perMonth', 0);
  const savingsValue = options.showAmtWithoutMinus ? Math.abs(value) : Math.min(0, value);
  return formatMonthlyPrice(savingsValue);
}

/**
 * Get Incentives After Delivery
 */
export const getIncentivesAfterDelivery = state => {
  return _get(state, 'Financial.incentives.total.incentiveAfterDelivery', 0);
};

/**
 * Get price before savings
 * @param  {Object} state
 * @param  {String} financeType
 * @param  {Object} options
 * @return {String}
 */
export function getPrice(
  state,
  financeType = 'cash',
  options = {
    short: false,
    afterSavings: false,
    showAmountIncludingFees: null,
    subtractVAT: false,
    savingsAmount: false,
    showSavingAmtWithoutMinus: false,
    formatCurrencyOpts: {
      useDynamicRounding: false,
    },
    cashPriceValue: false,
    includeAccessories: false,
    addOnlyVATwithSubtotal: false,
    correspondingStandardPlan: false,
  }
) {
  const { isCoinReloaded } = state?.App || {};
  const { formatWithPrecision = false } = state?.ReviewDetails || {};
  const includeFees = !_isNil(options.showAmountIncludingFees)
    ? options.showAmountIncludingFees
    : _get(state, 'FinancingOptions.showAmountIncludingFees', false);
  const showExVat = getShowExVat(state);
  const key =
    financeType === 'loan'
      ? options?.correspondingStandardPlan
        ? `calculatorResult.data.correspondingStandardFinanceResult.result.loan`
        : 'finance'
      : financeType;
  const Pricing = _get(state, `Pricing.${key}`, state.Pricing || {}) || {};
  const vehiclePrice = _get(state, 'Pricing.total');
  const showBeforeSavingsPostfix = _get(state.FinancingOptions, 'showBeforeSavingsPostfix', false);
  const hasPurchasePriceIncentives = _get(
    state,
    'Pricing.calculatorResult.data.apiResults.incentives.total.includedInPurchasePrice',
    null
  );
  const { formatCurrencyOpts = {}, formatSavingsAmount = true } = options;
  const isReservation = _get(state, 'ReviewDetails.product.isReservation', false);
  const reservationAmt = isReservation ? getReservationAmount(state) : null;

  // GrossPrice has the purchasePriceIncentives in there,
  // But the vehiclePrice needs adjusting.
  let cashBeforeSavings = reservationAmt ? reservationAmt.total : Pricing.grossPrice;
  let cashAfterSavings = Pricing.netPrice;
  const incentiveAfterDelivery = getIncentivesAfterDelivery(state);
  if (!includeFees && !isReservation) {
    cashBeforeSavings = vehiclePrice;
    cashAfterSavings = Pricing.priceExcludingSaving;
    const includePurchasePriceIncentives = _get(
      state,
      'ReviewDetails.includePurchasePriceIncentivesInTrims',
      true
    );
    const includeDocFeeInSummary = _get(state, 'ReviewDetails.includeDocFeeInSummary', false);
    if (includePurchasePriceIncentives && typeof hasPurchasePriceIncentives === 'number') {
      cashBeforeSavings += hasPurchasePriceIncentives;
      cashAfterSavings += hasPurchasePriceIncentives;
    }
    if (includeDocFeeInSummary) {
      cashBeforeSavings += getDestinationAndDocFee(state);
      cashAfterSavings += getDestinationAndDocFee(state);
    }
    if (incentiveAfterDelivery) {
      cashAfterSavings += incentiveAfterDelivery;
    }
  }
  // If we want to include ALL fees, except for VAT, we can do that:
  if (includeFees && options.subtractVAT) {
    const sctTax = getSCTTax(state).total ?? 0;
    const vatAmount = getVATAmount(state) + sctTax;
    cashBeforeSavings -= vatAmount;
    cashAfterSavings -= vatAmount;
  }

  if (!includeFees && options.addOnlyVATwithSubtotal) {
    const vatAmount = getVATAmount(state);
    cashBeforeSavings += vatAmount;
    cashAfterSavings += vatAmount;
  }

  // We pull the precision for cash totals from state, if not overridden by options.
  const cashRounding = _get(state, 'SummaryPanel.totalsRounding.cash', 0);
  if (typeof formatCurrencyOpts.precision === 'undefined') {
    formatCurrencyOpts.precision = cashRounding;
  }
  if (formatWithPrecision) {
    formatCurrencyOpts.useDynamicRounding = true;
  }

  // Show price without adjustment for HK
  const showWithoutAdjustments = _get(state, 'ReviewDetails.showWithoutAdjustments', false);

  if (
    showWithoutAdjustments &&
    financeType === 'cash' &&
    options.showAmountIncludingFees === false
  ) {
    const discount = _get(state, 'ReviewDetails.product.data.Discount', 0);
    cashBeforeSavings += discount;
    cashAfterSavings += discount;
  }

  if (options?.includeAccessories) {
    const accessoriesSubtotal = _get(state, 'Accessories.grossTotal', 0);
    cashBeforeSavings += accessoriesSubtotal;
    cashAfterSavings += accessoriesSubtotal;
  }
  const { monthlyPaymentPrecision = 0 } = _get(
    getFinanceProductData(state),
    'uiSettings.forms',
    {}
  );

  const useDynamicRounding = !monthlyPaymentPrecision ? false : true;
  const formatCurrencyOptions = {
    ...formatCurrencyOpts,
    formatWithPrecision,
    useDynamicRounding,
  };

  const financeProductId = getFinanceProductId(state);
  const leaseBeforeSavingsPostfix = i18n(
    'FinancingOptions.lease__beforeSavingsPostfix',
    null,
    null,
    {
      specificOverride: financeProductId,
      returnNullWhenEmpty: true,
    }
  );

  const loanBeforeSavingsPostfix = i18n('FinancingOptions.loan__beforeSavingsPostfix', null, null, {
    specificOverride: financeProductId,
    returnNullWhenEmpty: true,
  });

  if (isCoinReloaded && options?.includeTaxesAndFees) {
    const totalTaxes = getCombinedTaxesAndFees(state);
    cashBeforeSavings += totalTaxes;
    cashAfterSavings += totalTaxes;
  }
  if (isCoinReloaded) {
    const { credit = 0 } = getReferralCredit(state);
    cashBeforeSavings -= credit;
    cashAfterSavings -= credit;
  }
  const {
    monthlyPayment = 0,
    monthlyPaymentAfterSavings: monthlyAfterSavings,
    monthlyPaymentWithoutTaxesAndFees = 0,
    monthlyPaymentAfterSavingsWithoutTaxesAndFees = 0,
  } = Pricing?.output?.outputs || {};
  const finplatMonthly = options?.includeTaxesAndFees
    ? monthlyPayment
    : monthlyPaymentWithoutTaxesAndFees || monthlyPayment;
  const beforeSavings = {
    lease: `${formatMonthlyPrice(
      showExVat ? Pricing.monthlyPaymentWithoutVat : Pricing.monthlyPayment,
      formatCurrencyOptions
    )}${showBeforeSavingsPostfix && leaseBeforeSavingsPostfix ? leaseBeforeSavingsPostfix : ''}`,
    loan: `${formatMonthlyPrice(Pricing.monthlyPayment, formatCurrencyOptions)}${showBeforeSavingsPostfix && loanBeforeSavingsPostfix ? loanBeforeSavingsPostfix : ''
      }`,
    finplat: formatMonthlyPrice(finplatMonthly,formatCurrencyOptions),
    cash: Number.isNaN(cashBeforeSavings)
      ? formatCurrency(0, formatCurrencyOptions)
      : formatCurrency(cashBeforeSavings, formatCurrencyOptions),
  };

  const finplatMonthlyAfterSavings = options?.includeTaxesAndFees
    ? monthlyAfterSavings
    : monthlyPaymentAfterSavingsWithoutTaxesAndFees || monthlyAfterSavings;
  const afterSavings = {
    lease: formatMonthlyPrice(
      showExVat ? Pricing.netMonthlyPaymentWithoutVat : Pricing.netMonthlyPayment
    ),
    loan: formatMonthlyPrice(Pricing.netMonthlyPayment),
    finplat: formatMonthlyPrice(finplatMonthlyAfterSavings > 0 ? finplatMonthlyAfterSavings : 0, formatCurrencyOptions),
    cash: Number.isNaN(cashAfterSavings)
      ? formatCurrency(0, formatCurrencyOptions)
      : formatCurrency(cashAfterSavings, formatCurrencyOptions),
  };
  if (options.cashPriceValue) {
    return options.afterSavings ? cashAfterSavings : cashBeforeSavings;
  }

  // If we only want the savings amount, return that
  if (options.savingsAmount) {
    const tccSavingsAmount = _get(state, 'Financial.tcc.savingsTotal', null);

    if (tccSavingsAmount) {
      switch (financeType) {
        case FinanceTypes.FINPLAT:
        case 'lease':
        case 'loan': {
          if (tccSavingsAmount.monthly) {
            return formatMonthlyPrice(tccSavingsAmount.monthly, formatCurrencyOptions);
          }
          break;
        }
        default: {
          if (tccSavingsAmount.once) {
            return formatCurrency(tccSavingsAmount.once, formatCurrencyOptions);
          }
        }
      }
    }

    const value = Pricing.netMonthlyPayment - Pricing.monthlyPayment;
    const savingsValue = options.showSavingAmtWithoutMinus ? Math.abs(value) : Math.min(0, value);
    const savingsAmount = {
      lease: showExVat
        ? Pricing.netMonthlyPaymentWithoutVat - Pricing.monthlyPaymentWithoutVat
        : savingsValue,
      loan: savingsValue,
      finplat: getFuelSavingsPerMonth(state, { showAmtWithoutMinus: true }),
      cash: options.showSavingAmtWithoutMinus
        ? Math.abs(Pricing.totalSavingsAmount)
        : Math.min(0, Pricing.totalSavingsAmount),
    };
    if (formatSavingsAmount) {
      savingsAmount.lease = formatMonthlyPrice(savingsAmount.lease, formatCurrencyOptions);
      savingsAmount.loan = formatMonthlyPrice(savingsAmount.loan, formatCurrencyOptions);
      savingsAmount.cash = isNaN(savingsAmount.cash)
        ? formatCurrency(0, formatCurrencyOptions)
        : formatCurrency(savingsAmount.cash, formatCurrencyOptions);
    }

    return savingsAmount[financeType];
  }

  return options.afterSavings ? afterSavings[financeType] : beforeSavings[financeType];
}

export function getStandardFinancePlanInfo(state) {
  const financePlanMatrix = _get(state, 'Forms.loanData.financePlanMatrix', []);
  return _head(financePlanMatrix.filter(({ isStandardPlan }) => isStandardPlan));
}

export function getCurrentFinancePlanInfo(state) {
  const financeType = getFinanceType(state);
  const currentFinancePlanId = _get(state, `Forms.${financeType}Data.params.financePlanId`, '') || getDefaultFinancePlanId({state});
  const financePlanMatrix = _get(state, `Forms.${financeType}Data.financePlanMatrix`, []);
  return _head(financePlanMatrix.filter(({ value }) => value === currentFinancePlanId));
}

export function getMaximumSaving(state) {
  const standardMonthlyPayment = _get(
    state,
    'Pricing.calculatorResult.data.correspondingStandardFinanceResult.result.loan.monthlyPayment',
    0
  );
  const currentMonthlyPayment = _get(
    state,
    'Pricing.calculatorResult.data.result.loan.monthlyPayment',
    0
  );
  const currentTerms = _get(state, 'Pricing.calculatorParameters.loanTerm', 0);
  return (standardMonthlyPayment - currentMonthlyPayment) * currentTerms;
}

/**
 * Get price after savings
 * @param  {Object} state
 * @param  {String} financeType
 * @param  {Object} options
 * @return {String}
 */
export function getPriceAfterSavings(
  state,
  financeType = 'cash',
  options = { short: false, showAmountIncludingFees: null, showTotalPriceAfterSavings: false }
) {
  const updatedOptions = {
    ...options,
    afterSavings: true,
  };

  if (options.showTotalPriceAfterSavings) {
    return getPrice(state, 'cash', updatedOptions);
  }

  return getPrice(state, financeType, updatedOptions);
}

/**
 * Get maximum downPayment amount
 */
export function getMaxDownPaymentAmount(maxDown, grossPrice) {
  return maxDown <= 1 ? maxDown * grossPrice : maxDown;
}

/**
 * Get downPayment to be sent to the calculator
 */
export function getDownPayment(
  state,
  {
    context = 'form',
    grossPrice = null,
    excludeVAT = false,
    preservePercentage = false,
    financeType = null,
    financeTabId = null,
    financePlanId = null,
    loanTerm = null,
  } = {}
) {
  const financeTypeUsed = financeType || getFinanceType(state);
  let downPayment = 0;
  const { Pricing = {} } = state;
  let grossPriceValue = _isNumber(grossPrice) ? grossPrice : Pricing.grossPrice || 0;
  const financeProductData = getFinanceProductData(state, financeTabId);
  const trim = state.CustomGroups?.TRIM?.currentSelected?.[0]?.code ?? '';
  const model = getModel(state);
  const { downPayments = {}, downPayment: variableDownpayment = 0, downPaymentExVatMode = false } =
    financeProductData?.variables ?? {};

  if (context === 'form') {
    const maxDownPayment =
      {
        [FinanceTypes.LOAN]: Pricing.finance?.downPayment?.normalizedMaximumDownPayment,
        [FinanceTypes.LEASE]: Pricing.lease?.maximumDownPayment,
        [FinanceTypes.FINPLAT]: Pricing.finplat?.output?.outputs?.maxCashDownPayment,
      }[financeTypeUsed] || Infinity;

    const downPaymentForTrim = downPayments?.trim?.[trim];
    const downPaymentForModel = downPayments?.model?.[model];
    const userInput = state.SummaryPanel?.downPayment;
    const downPaymentRangeMatrix = getDownPaymentRangeMatrix(state, financePlanId, null, loanTerm);

    downPayment = userInput ?? downPaymentForTrim ?? downPaymentForModel ?? variableDownpayment;
    downPayment = financePlanId ? downPaymentRangeMatrix?.defaultAmount : downPayment;

    if (maxDownPayment && downPayment > 1 && downPayment > maxDownPayment) {
      downPayment = Math.floor(maxDownPayment);
    }
  } else {
    downPayment =
      {
        [FinanceTypes.LOAN]: Pricing.finance?.downPayment?.downPaymentAmount,
        [FinanceTypes.LEASE]: Pricing.lease?.downPayment,
        [FinanceTypes.FINPLAT]: Pricing.finplat?.output?.inputs?.cashDownPayment,
      }[financeTypeUsed] ?? 0;
  }

  if (downPayment > 0 && downPayment < 1 && !preservePercentage && !downPaymentExVatMode) {
    const { maxVehiclePriceForEcoBonus = 0, excludeEcoBonus = 0 } =
      state.Forms.leaseData?.params ?? {};

    if (!isInventory(state) && grossPriceValue < maxVehiclePriceForEcoBonus) {
      grossPriceValue += excludeEcoBonus;
    }

    if (excludeVAT) {
      return Math.ceil((downPayment * grossPriceValue) / (1 + getVATPercent(state) / 100));
    }

    return downPayment * grossPriceValue;
  }

  return downPayment;
}

/**
 * Get downPaymentRangeMatrix (range by amount)
 * @param {} state
 * @param {} financePlanId
 * @param {} grossPrice
 * @returns {Object} downPaymentRangeMatrix
 */
export function getDownPaymentRangeMatrix(state, financePlanId, grossPrice = null, loanTerm = null) {
  const productData = getFinanceProductData(state);
  const grossPriceFromPricing = _get(state, 'Pricing.grossPrice', -1);
  const gPrice = grossPriceFromPricing > 0 ? grossPriceFromPricing : grossPrice;
  const financeType = getFinanceType(state);
  const financePlan = financePlanId || getDefaultFinancePlanId({state});
  const selectedTerm = loanTerm || _get(state,  'Pricing.calculatorParameters.loanTerm');
  const getAmount = (price, amount, percent) => {
    return amount ? amount : percent > 0 ? Math.round(price * percent) : 0;
  }
  const getPercent = (price, percent, amount) => {
    return percent ? percent : amount > 0 ? parseFloat((amount/price).toFixed(2)) : 0
  }
  return financePlanId
      ? _get(productData, 'downPaymentRangeMatrix', [])
      ?.filter(obj => obj.financePlanId === financePlan && (
          (financeType === FinanceTypes.LOAN && obj?.loanTerm === undefined || obj.loanTerm === selectedTerm) ||
          (financeType === FinanceTypes.LEASE && obj?.leaseTerm === undefined || obj.leaseTerm === selectedTerm)
          )
      )
      ?.map(mappedObject => {
        const {
          defaultAmount = 0,
          defaultPercent = 0,
          minimumAmount = 0,
          minimumPercent = 0,
          maximumAmount = undefined,
          maximumPercent = 1,
          loanTerm,
          leaseTerm,
          financePlanId
        } = mappedObject;
        const commonAttribute = {
          isFixed : minimumAmount === maximumAmount || minimumPercent === maximumPercent,
          financePlanId,
          ...financeType === FinanceTypes.LOAN ? {loanTerm} : {},
          ...financeType === FinanceTypes.LEASE ? {leaseTerm} : {},
        }
        const amountInfo = {
          defaultAmount: getAmount(gPrice, defaultAmount, defaultPercent),
          minimumAmount: getAmount(gPrice, minimumAmount, minimumPercent),
          maximumAmount: getAmount(gPrice, maximumAmount, maximumPercent),
        }
        const percentInfo = {
          defaultPercent: getPercent(gPrice, defaultPercent, defaultAmount),
          minimumPercent: getPercent(gPrice, minimumPercent, minimumAmount),
          maximumPercent: getPercent(gPrice, maximumPercent, maximumAmount),
        }
        return  {...amountInfo, ...percentInfo, ...commonAttribute};
      })?.[0] || {}
      : {};
}


export function getResidualInfo(state, { selectedTerm = null } = {}) {
  const currentFinancePlanId = _get(state, 'Forms.loanData.params.financePlanId', '');
  const terms = selectedTerm || _get(state,  'Pricing.calculatorParameters.loanTerm');
  const residualMatrix = _get(state, 'Forms.loanData.residualRateMatrix', []);
  const residualInfo =  residualMatrix.filter(({financePlanId, loanTerm}) => {
    return currentFinancePlanId === financePlanId && terms === loanTerm
  });
  return _head(residualInfo, {});
}

/**
 * Get defaultPercent from downPaymentRangeMatrix
 * @param {} state
 */
export function getDefaultDownPaymentPercentFromMatrix(state, selectedFinanceProduct) {
  const downPaymentRangeMatrix = getDownPaymentRangeMatrix(state, selectedFinanceProduct);
  return downPaymentRangeMatrix?.defaultPercent ?? -1;
}

/**
 * Get defaultAmount from downPaymentRangeMatrix
 * @param {} state
 */
export function getDefaultDownPaymentAmountFromMatrix(
  state,
  selectedFinanceProduct,
  grossPrice = null
) {
  const downPaymentRangeMatrix = getDownPaymentRangeMatrix(
    state,
    selectedFinanceProduct,
    grossPrice
  );
  return downPaymentRangeMatrix?.defaultAmount ?? -1;
}

export function getDefaultFinancedAmountFromMatrix(
  state,
  selectedFinanceProduct,
  grossPrice = null
) {
  const downPaymentAmount = getDefaultDownPaymentAmountFromMatrix(
    state,
    selectedFinanceProduct,
    grossPrice
  );
  const grossPriceFromPricing = _get(state, 'Pricing.grossPrice', -1);
  const gPrice = grossPriceFromPricing > 0 ? grossPriceFromPricing : grossPrice;
  if (gPrice > 0 && downPaymentAmount > 0) {
    return gPrice - downPaymentAmount;
  }
  return -1;
}

export function getTermOptions(state, financePlanId) {
  const productData = getFinanceProductData(state);
  const financeType = getFinanceType(state);
  const fmsProps = _get(productData, `${financeType}TermsOffered`, {});
  const financePlan = financePlanId || getDefaultFinancePlanId({state});
  const termOptions = (_get(fmsProps, 'loanTermMatrix')
    ? findMatrixValue(_get(fmsProps, 'loanTermMatrix'), { financePlan })
    : _get(fmsProps, 'loanTerm', [])
  ).reduce((r, v) => {
    r.push({ label: i18n('FinancingOptions.termOptionLabel', { TERM: v }), value: v });
    return r;
  }, []);
  return termOptions;
}

export function getAprMatrix(state, financePlanId) {
  const productData = getFinanceProductData(state);
  const financeType = getFinanceType(state);
  const term = _get(state, `Pricing.calculatorParameters.${financeType}Term`, null);
  const financePlan = financePlanId || getDefaultFinancePlanId({state});
  const aprMatrix =
    _get(productData, 'aprMatrix', [])
      ?.filter(obj => {
        return obj.financePlanId === financePlan && obj[`${financeType}Term`] === term;
      })
      .map(obj => ({
        value: obj.value,
        annualInterestRate: obj.annualInterestRate,
      }))?.[0] || {};
  return aprMatrix;
}

export function getDefaultFinancePlanId({ state, selectedFinanceType, trimCode}) {
  const model = getModel(state);
  const trim = trimCode || getTrimCode(state);
  const financeType = selectedFinanceType || getFinanceType(state);
  const defaultFinancePlanId = _get(state, `Financial.fms_${financeType}.${financeType}`, [])?.[0]?.financePlanMatrix?.filter((obj) => {
    return (obj.variant?.includes(model) || obj.variant === undefined) && (obj.options?.includes(trim) || obj.options === undefined);
  })?.[0]?.value;
  return defaultFinancePlanId;
}

/**
 * Get balloon payment from state
 * @param {} state
 */
export function getBalloonPayment(state, { context = '' } = {}) {
  const financeType = getFinanceType(state);
  const useResidualAmount = _get(state, 'Forms.useResidualAmount');

  if (financeType === FinanceTypes.LOAN) {
    if (useResidualAmount) {
      return _get(state, 'Pricing.finance.residualAmount');
    }
    if (context === 'form') {
      return _get(state, 'Pricing.finance.balloonPayment');
    }

    const productData = getFinanceProductData(state);
    const userInput = _get(state, 'SummaryPanel.balloonPayment');

    return userInput != null ? userInput : _get(productData, 'variables.balloonPayment', null);
  }

  return null;
}

export function shouldExcludeDefaultDownpaymentVAT(
  state,
  { financeType = null, financeProductId = null } = {}
) {
  const excludeDefaultDownpaymentVAT = _get(
    state,
    'FinancingOptions.excludeDefaultDownpaymentVAT',
    false
  );

  const financeProduct = financeProductId || getFinanceProductId(state);

  if (Array.isArray(excludeDefaultDownpaymentVAT)) {
    const financeTypeUsed = financeType || getFinanceType(state);
    return (
      excludeDefaultDownpaymentVAT.includes(financeTypeUsed) ||
      excludeDefaultDownpaymentVAT.includes(`${financeType}.${financeProduct}`)
    );
  }

  return excludeDefaultDownpaymentVAT;
}

/**
 * This will back calculate the downpayment percentage based on current downpayment and grossPrice.
 * @param state
 * @param financeType
 */
export function getDownPaymentPercentage(state, { financeType = null } = {}) {
  const currentFinanceType = financeType || getFinanceType(state);

  if (currentFinanceType === FinanceTypes.LOAN) {
    // Loan always has the percentage returned in the result.
    return _get(state.Pricing, 'finance.downPayment.downPaymentPercent', 0);
  }

  const selectedFinanceProduct = getFinanceProductId(state);
  if (selectedFinanceProduct === 'operational_lease' && state.Pricing?.lease?.downPaymentPercent) {
    return state.Pricing.lease.downPaymentPercent;
  }

  const excludeVAT = shouldExcludeDefaultDownpaymentVAT(state, { financeType });
  const grossPriceWithVat = _get(state.Pricing, 'grossPrice', 0);
  const currentDownPayment = getDownPayment(state, { grossPriceWithVat, excludeVAT });

  const grossPriceKey = excludeVAT ? 'lease.grossPriceWithoutVat' : 'grossPrice';
  const grossPrice = _get(state.Pricing, grossPriceKey, 0);
  return grossPrice > 0 ? (currentDownPayment / grossPrice) * 100 : 0;
}

/**
 * Will calculate the DownPayment by selected percentage of grossPrice.
 * @param grossPrice
 * @param downPaymentPercentage
 * @return downPayment
 */
export function getDownPaymentByPercentage(grossPrice, downPaymentPercentage) {
  return grossPrice > 0 ? grossPrice * downPaymentPercentage : 0;
}

/**
 * Will calculate the DownPayment by selected percentage of grossPrice.
 * @param grossPrice
 * @param downPayment
 * @return downPaymentPercentage
 */
export function getPercentageByDownPayment(grossPrice, downPayment) {
  return grossPrice > 0 && downPayment > 0
    ? parseInt(((downPayment / grossPrice) * 100).toFixed()) / 100
    : 0;
}

/**
 * Get terms used in financial forms
 * @param {*} financeType
 * @param {*} state
 */
function getTerms(financeType, state, selectedFinanceProduct = null) {
  const { Pricing, SummaryPanel } = state;
  const productData = getFinanceProductData(state);
  const loanType = _get(SummaryPanel, 'loanType', null);
  const loanTerm = _get(Pricing, 'calculatorParameters.loanTerm', null);
  const leaseTerm = getLeaseTerm(state);
  const fmsProps = _get(productData, `${financeType}TermsOffered`, {});
  const distancesOffered = getFinanceProductDistancesOffered(state);
  const maxLoanTerm = _get(Pricing, 'finance.maxLoanTerm', Infinity);
  const maxLeaseTerm = _get(Pricing, 'lease.maxLeaseTerm', Infinity);
  const maxLeaseDistance = _get(Pricing, 'lease.maxLeaseDistance', Infinity);
  const trimCode = getTrimCode(state);
  const modelCode = state?.Configuration?.model_code ?? '';
  const { market } = state?.OMS?.oms_params || {};
  const financePlanId =
    selectedFinanceProduct || getDefaultFinancePlanId({state});
  const downPaymentRangeMatrix = getDownPaymentRangeMatrix(state, financePlanId);
  const downPayment = (() => {
    if (financeType === FinanceTypes.LOAN) {
      return (
        _get(Pricing, 'finance.downPayment.downPaymentAmount') ||
        downPaymentRangeMatrix?.defaultAmount
      );
    }

    return _get(Pricing, 'lease.downPayment') || downPaymentRangeMatrix?.defaultAmount;
  })();
  const downPaymentParentKey = financeType === FinanceTypes.LOAN ? 'finance' : financeType;
  const downPaymentPercent =
    market === 'CN'
      ? _get(Pricing, `${downPaymentParentKey}.downPaymentPercent`) ||
        downPaymentRangeMatrix?.defaultPercent
      : undefined;

  const variables = {
    ..._get(productData, 'variables', {}),
    financePlanId,
    downPayment,
    downPaymentPercent,
  };
  const uiSettings = _get(productData, 'uiSettings.forms', {});
  let downPaymentPercentMatrix = _get(variables, 'downPaymentPercentMatrix', []);
  let aldDownPaymentValues = _get(variables, 'downPaymentMatrix', []);
  const isDownPaymentMonths = Math.max(...aldDownPaymentValues) <= 24;
  const effectiveRate = parseFloat((_get(state, 'Pricing.finance.effectiveRate', 0) * 100).toFixed(2));

  switch (financeType) {
    case 'lease':
      return {
        [`${financeType}Data`]: {
          params: { ...variables },
          leaseTerms: _get(fmsProps, 'leaseTerm', [])
            .filter(term => term <= maxLeaseTerm)
            .reduce((r, v) => {
              r.push({ label: i18n('FinancingOptions.termOptionLabel', { TERM: v }), value: v });
              return r;
            }, []),
          annualLeaseDistances: distancesOffered
            .filter(distance => distance <= maxLeaseDistance)
            .reduce((r, v) => {
              r.push({
                label: i18n('FinancingOptions.lease__distanceOptionLabel', {
                  DISTANCE: formatNumber(v),
                }),
                value: v,
              });
              return r;
            }, []),
          downPaymentMatrix: downPaymentPercentMatrix.reduce((r, v) => {
            r.push({
              label: i18n('FinancingOptions.downPaymentPercentage', {
                DPP: formatNumber(v * 100),
              }),
              value: v,
            });
            return r;
          }, []),
          aldDownPaymentValues: aldDownPaymentValues.reduce((r, v) => {
            r.push({
              label: isDownPaymentMonths
                ? i18n('FinancingOptions.termOptionLabel', { TERM: v })
                : formatCurrency(v),
              value: v,
            });
            return r;
          }, []),
          interestRateTypes: _get(variables, 'interestRateTypes', []).map(interestRateType => {
            return {
              label: i18n(`FinancingOptions.interestRateTypes.${interestRateType}`),
              value: interestRateType,
            };
          }),
          financePlanMatrix: _get(productData, 'financePlanMatrix', []).filter((obj) => {
            return obj.options?.includes(trimCode) || obj.options === undefined;
          }).map(({value, ...rest}) => ({
            label: i18n(`FinancingOptions.productName__${value}`),
            value: value,
            ...rest
          })),
          aprMatrix: _get(productData, 'aprMatrix', [])?.filter((obj) => {
            return obj.financePlanId === financePlanId && obj.leaseTerm === leaseTerm;
          }).map((obj) => ({
            value: obj.value,
            annualInterestRate: obj.annualInterestRate,
          }))?.[0] || {},
          downPaymentRangeMatrix: downPaymentRangeMatrix,
        },
        ...uiSettings,
      };
    case 'loan':
      const { interestRatesOffered = [] } = Pricing?.finance || {};
      return {
        [`${financeType}Data`]: {
          params: {
            ...variables,
            interestRateMatrix: _get(fmsProps, 'interestRateMatrix', []),
          },
          loanTerms: (_get(fmsProps, 'loanTermMatrix')
            ? findMatrixValue(_get(fmsProps, 'loanTermMatrix'), {
                type: loanType,
                ...(financePlanId && { financePlanId }),
              })
            : _get(fmsProps, 'loanTerm', []).filter(term => term <= maxLoanTerm)
          ).reduce((r, v) => {
            r.push({ label: i18n('FinancingOptions.termOptionLabel', { TERM: v }), value: v });
            return r;
          }, []),
          balloonPayments: (_get(fmsProps, 'balloonPaymentMatrix')
            ? findMatrixValue(_get(fmsProps, 'balloonPaymentMatrix'), {
              type: loanType,
              term: loanTerm,
            })
            : _get(fmsProps, 'balloonPayment', [])
          ).reduce((r, v) => {
            r.push({
              label: i18n('FinancingOptions.balloonPaymentLabel', {
                BALLOON_PAYMENT: formatNumber(v < 1 ? v * 100 : v),
              }),
              value: v,
            });
            return r;
          }, []),
          residualAmountMatrix: (_get(fmsProps, 'residualRateMatrix')
            ? findMatrixValue(_get(fmsProps, 'residualRateMatrix'), {
              term: loanTerm,
            })
            : []
          ).reduce((r, v) => {
            r.push({
              label: i18n('FinancingOptions.residualAmountLabel', {
                BALLOON_PAYMENT: formatNumber(v < 1 ? v * 100 : v),
              }),
              value: v,
            });
            return r;
          }, []),
          loanAprs: _get(fmsProps, 'interestRate', interestRatesOffered).reduce((r, v) => {
            r.push({ label: i18n('FinancingOptions.apr', { APR: v }), value: v });
            return r;
          }, []),
          loanDistances: distancesOffered.reduce((r, v) => {
            r.push({
              label:
                i18n('FinancingOptions.mileageLabel').indexOf('{MILEAGE}') !== -1
                  ? i18n('FinancingOptions.mileageLabel', { MILEAGE: formatNumber(v) })
                  : formatNumber(v),
              value: v,
            });
            return r;
          }, []),
          downPaymentMatrix: _get(variables, 'downPaymentPercentMatrix', []).reduce((r, v) => {
            r.push({
              label: i18n('FinancingOptions.downPaymentPercentage', {
                DPP: formatNumber(v * 100),
              }),
              value: v,
            });
            return r;
          }, []),
          residualRateMatrix: _get(productData, 'residualRateMatrix', []),
          financePlanMatrix: _get(productData, 'financePlanMatrix', [])
            .filter(obj => {
              return (obj.options?.includes(trimCode) || obj.options === undefined) &&
                  (obj.variant?.includes(modelCode) || obj.variant === undefined);
            })
            .map(({ value, ...rest }) => ({
              label: i18n(`FinancingOptions.productName__${value}`),
              value,
              ...rest
            })),
          aprMatrix:
            _get(productData, 'aprMatrix', [])
              ?.filter(obj => {
                return obj.financePlanId === financePlanId && obj.loanTerm === loanTerm;
              })
              .map(obj => ({
                value: obj.value ?? effectiveRate,
                annualInterestRate: obj.annualInterestRate,
              }))?.[0] || {},
          downPaymentRangeMatrix: downPaymentRangeMatrix,
        },
        ...uiSettings,
      };
    default:
      return {};
  }
}

/**
 * Get lease term from state
 * @param state object Current main redux state
 * @param financeTabId
 */
export function getLeaseTerm(state, financeTabId = null) {
  const term = _get(state, 'SummaryPanel.leaseTerm', null);
  const termsOffered = getFinanceProductTermsOffered(state, financeTabId);
  const trimCode = getTrimCode(state);

  const localeSpecific = getFinanceProductVariable(state, 'localeSpecific', financeTabId);
  const maxTerm = _get(localeSpecific, 'defaultToMaxTerm', false)
    ? Math.max(...termsOffered)
    : null;

  if (term != null && termsOffered.includes(term)) {
    return term;
  }

  return (
    maxTerm ||
    getFinanceProductVariable(state, `leaseTerm__${trimCode}`, financeTabId) ||
    getFinanceProductVariable(state, `leaseTerm__${getModel(state)}`, financeTabId) ||
    getFinanceProductVariable(state, 'leaseTerm', financeTabId)
  );
}

export function getInterestRate(state) {
  let financeType = getFinanceType(state);
  if (financeType === FinanceTypes.LOAN) {
    financeType = 'finance';
    const initialInterestRate = _get(state, `Pricing.finance.regional.initialInterestRate`);
    if (initialInterestRate) {
      return initialInterestRate;
    }
    const selectedLoanAPR = getLoanInterestRate(state);

    if (selectedLoanAPR !== null) {
      return selectedLoanAPR;
    }
  } else if (financeType === FinanceTypes.LEASE) {
    const financeProductData = getFinanceProductData(state);
    const interestRateMatrix = _get(financeProductData, 'interestRateMatrix', []);
    const selectedLeaseTerm = _get(state, 'Pricing.lease.leaseTerm') || getLeaseTerm(state);

    const financePlanId = getDefaultFinancePlanId({state}) || _get(state, `Forms.leaseFinancePlanId`, '');
    const aprMatrix = _get(financeProductData, 'aprMatrix', []);

    if (aprMatrix?.length > 0) {
      const result = aprMatrix.find((obj) =>
        obj.financePlanId === financePlanId && obj.leaseTerm === selectedLeaseTerm
      );
      return result;
    } else {
      return _get(
        _find(
          interestRateMatrix,
          leaseItem => leaseItem.leaseTerm && leaseItem.leaseTerm === selectedLeaseTerm
        ),
        'value',
        _get(state, 'Pricing.lease.interestRate')
      );
    }

  } else if (financeType === FinanceTypes.FINPLAT) {
    return state?.Pricing?.finplat?.output?.inputs?.interestRate ?? 0;
  }

  return _get(state, `Pricing.${financeType}.interestRate`);
}

/**
 * Get loan term from state
 * @param {} state
 */
export function getLoanTerm(state) {
  let userInput = _get(state, 'SummaryPanel.loanTerm', null);
  const trimCode = getTrimCode(state);

  if (Array.isArray(userInput)) {
    userInput = _get(state, userInput[0]);
  }
  const financePlanId = _get(state, 'Forms.loanData.params.financePlanId', '') || getDefaultFinancePlanId({state});
  const productData = getFinanceProductData(state);
  const fmsProps = _get(productData, 'loanTermsOffered', {});
  const loanType = _get(state, 'SummaryPanel.loanType', null);
  const maxLoanTerm = _get(state, 'Pricing.finance.maxLoanTerm', Infinity);
  const loanData = (_get(fmsProps, 'loanTermMatrix')
    ? findMatrixValue(_get(fmsProps, 'loanTermMatrix'), {
        type: loanType,
        ...(financePlanId && { financePlanId }),
      })
    : _get(fmsProps, 'loanTerm', []).filter(term => term <= maxLoanTerm)
  ).reduce((r, v) => {
    r.push({ label: i18n('FinancingOptions.termOptionLabel', { TERM: v }), value: v });
    return r;
  }, []) || [];
  const loanTerms = loanData.map(
    term => term.value
  );
  userInput = userInput !== null && loanTerms.indexOf(userInput) === -1 ? null : userInput;

  const localeSpecific = getFinanceProductVariable(state, 'localeSpecific');
  const maxTerm = _get(localeSpecific, 'defaultToMaxTerm', false) ? Math.max(...loanTerms) : null;

  return (
    userInput ||
    maxTerm ||
    getFinanceProductVariable(state, `loanTerm__${trimCode}`) ||
    getFinanceProductVariable(state, 'loanTerm')
  );
}

/**
 * Get loan APR from state
 * @param {} state
 */
export function getLoanInterestRate(state) {
  let userInput = _get(state, 'SummaryPanel.loanApr', null);
  if (Array.isArray(userInput)) {
    userInput = _get(state, userInput[0]);
  }

  // Converts string to number if string only contains an integer or float
  if (_isString(userInput) && /^(\d+(\.\d*)?|\.\d+)$/.test(userInput)) {
    userInput = _toNumber(userInput) || userInput; // fallback if _toNumber fails
  }

  return userInput || getFinanceProductVariable(state, 'loanApr');
}

/**
 * Function to populate financial terms state
 * @param  {Object} state [Redux state]
 * @return {Object} containing everything needed to display terms in UI
 */
export function getFinancialTerms(state, selectedFinanceProduct = null) {
  const financeType = getFinanceType(state);
  if (financeType === 'cash') {
    return {};
  }
  const financePlanId =
    selectedFinanceProduct || _get(state, `Forms.${financeType}Data.params.financePlanId`, '');
  return getTerms(financeType, state, financePlanId);
}

/**
 * Return active finance types
 * @param  {Object} state -- redux state
 * @return {String} -- array of financeTypes: [cash, loan, lease]
 */
export function getActiveFinanceTypes(state, forCalculator = false) {
  const currentFinanceType = getFinanceType(state);
  const financeTypes = Object.values(FinanceTypes).reduce(
    (r, financeType) => {
      if ([FinanceTypes.LEASE, FinanceTypes.LOAN, FinanceTypes.FINPLAT].includes(financeType)) {
        // We only want to fire the calculator at most twice:
        // once for cash, and once for whatever the current financeType is
        if (forCalculator && currentFinanceType !== financeType) {
          return r;
        }
        // make sure the injection for each financeType exists
        if (_get(state, `Financial.fms_${financeType}.${financeType}`, []).length) {
          r.push(financeType);
        }

        if (financeType === FinanceTypes.FINPLAT) {
          const finplatProducts = _get(state, `Financial.fms_${FinanceTypes.FINPLAT}`, {});
          if (Object.keys(finplatProducts).length) {
            r.push(FinanceTypes.FINPLAT);
          }
        }
      }
      return r;
    },
    forCalculator ? [] : ['cash']
  );

  if (forCalculator) {
    // current finance type needs to be fired last when used in the calculator
    if (currentFinanceType === 'cash') {
      financeTypes.push('cash');
    } else {
      financeTypes.unshift('cash');
    }
  }
  return financeTypes;
}

/**
 * Get currently selected finance product type.
 * Possible values (not exhaustive):
 *   loan, lease, auto_lease, auto_loan.
 *
 * @return {String}
 */
export const getProductType = product => {
  const id = product ? product.split(':')[0] : '';
  return FinanceTypes?.[id] || id || null;
};

/**
 * Similar to getActiveFinanceTypes, but types will only return a list of predefined types,
 * see FinanceTypes array in the dictionary.js (currently: lease, loan and cash).
 * Because we want to support multiple products per type (for example, 2 types of lease products),
 * we need a flat array of all finance products active.
 *
 * @param state
 * @return array
 */
export function getActiveFinanceProducts(state, { sourceType } = {}) {
  const { Configuration, FinancingOptions } = state;
  const { option_codes = [] } = Configuration;
  const { hideFinanceProduct } = FinancingOptions || {};
  const trimCode = getTrimCode(state);
  const availableTabs = _get(state, 'SummaryPanel.tabs', []);
  const market = _get(state, 'OMS.oms_params.market', '');
  const financeProducts = [FinanceTypes.LEASE, FinanceTypes.LOAN].reduce(
    (products, financeType) => {
      const activeProducts = _get(state, `Financial.fms_${financeType}.${financeType}`, []).reduce(
        (active, product) => {
          /**
           * "hideFinanceProduct" is existing implementation of hiding a product at DS Services level
           * "hideForTrims" is for hiding a product by setting the property on itself (mainly done by updating the product in FinCMS)
           */
          const { selected_by, hideForTrims = [] } = product || {};
          let productName = financeType;
          if (product.id) {
            productName += `.${product.id}`;
          }

          if (hideForTrims.includes(trimCode)) {
            return active;
          }

          if (selected_by && !parseSelectedBy(selected_by, option_codes)) {
            return active;
          }

          if (market === 'CN' && isUsedInventory(state) && financeType === FinanceTypes.LEASE) {
            return active;
          }

          if (!product.incomplete) {
            active.push(productName);
          }

          return active;
        },
        []
      );
      return [...products, ...activeProducts];
    },
    []
  );

  // Add Finplat products
  const finplatProducts = _get(state, `Financial.fms_finplat`, {});
  Object.keys(finplatProducts)
    .sort()
    .reduce((products, productKey) => {
      // @todo Check for customerType / trimCodes?
      // temp fix until we move this change to finplat
      if (hideFinanceProduct) {
        const { trim = [], financeType, conditions, falconDisabled, tabsSource = [] } = hideFinanceProduct || {};
        const pType = getProductType(productKey);
        const targetValue = _get(state, conditions?.source);
        const hideTabs = tabsSource?.includes(sourceType) || tabsSource?.includes('default');
        if (hideTabs && (financeType.includes(pType) || financeType.includes(productKey))) {
          if (trim.includes(trimCode)) {
            return products;
          }
          if (conditions && !matchesTargetValue(targetValue, conditions)) {
            return products;
          }
          if (falconDisabled) {
            const leaseCountries = _get(state, 'ReviewDetails.product.data.LeaseCountries') || [];
            const loanCountries = _get(state, 'ReviewDetails.product.data.LoanCountries') || [];
            if ((FinanceTypes.LEASE === pType && !leaseCountries.includes(market)) || (FinanceTypes.LOAN === pType && !loanCountries.includes(market))) {
              return products;
            }
          }
        }
      }
      products.push(`${FinanceTypes.FINPLAT}.${productKey}`);
      return products;
    }, financeProducts);

  // Add cash
  const tabs = filterFinanceTabs(state, [FinanceTypes.CASH, ...financeProducts]);
  const tabSequence = _get(state, 'FinancingOptions.tabSequence', null);
  const sortedTabs = tabSequence ? tabSequence?.map(x => {
    return tabs?.find(tab => tab?.split('.')[0] === x);
  }).filter(x => x !== undefined) : tabs;
  return availableTabs.length ? _intersection(sortedTabs, availableTabs) : sortedTabs;
}

/**
 * Creates a list of the available finance options with translated label.
 *
 * @param state
 * @param exCash
 * @returns {*}
 */
export function getFinanceTabs(state, { allCaps = false, exCash = false, sourceType = DEFAULT_FIN_TABS } = {}) {
  let activeFinanceProducts = getActiveFinanceProducts(state, { sourceType });
  const modelCode = state?.Configuration?.model_code ?? '';
  const { language } = state?.App ?? {};

  if (exCash) {
    activeFinanceProducts = activeFinanceProducts.filter(tab => tab !== FinanceTypes.CASH);
  }

  return activeFinanceProducts.map(tab => {
    const tabId = getFinanceProductId({}, tab);
    const financeType = getFinanceType({}, tab);
    const productData = getFinanceProductData(state, tab, financeType);

    let label =
      productData?.name?.[language] ??
      i18n(financeType, null, 'common.ui', {
        specificOverride: [tabId, modelCode].filter(item => item),
      });

    if (financeType === FinanceTypes.FINPLAT) {
      label = _get(state, `Financial.fms_finplat.${tabId}.parameters.name`, 'Unknown');
    }

    return {
      id: tab,
      financeProductId: tabId,
      financeType,
      customerType: (
        productData?.customerType ??
        (productData?.parameters?.customerType && [CUSTOMER_TYPES?.[productData?.parameters.customerType]])
      ),
      label,
      text: label,
    };
  });
}

/**
 * Returns reservation deposit amount due upfront (if applicable)
 * @param {Object} state [redux state]
 * @returns {number|null} [reservation deposit amount]
 */
export const getReservationDepositAmount = state => {
  if (isReservationOrder(state)) {
    const { reservationAmountDue: orderPaymentFromCore = null } = state?.ApplicationFlow || {};
    const { market } = state?.OMS?.oms_params || {};
    if (orderPaymentFromCore || (market === 'CN' && orderPaymentFromCore === 0)) {
      return orderPaymentFromCore;
    }
  }
  return null;
};

/**
 * Returns deposit amount due upfront (if applicable)
 * @param  {Object} state [redux state]
 * @return {Number}       [deposit amount]
 */
export function getDepositAmount(
  state,
  getPaymentSourceSubType = false,
  context = 'default',
  currency
) {
  const { isOrderFeeAPIEnabled } = state?.App || {};
  const market = _get(state, 'OMS.oms_params.market');
  const orderPaymentFromCore = getReservationDepositAmount(state);
  let { orderPayment, depositAmount } = state?.Payment || {};
  if (isOrderFeeAPIEnabled) {
    const { amount = 0, alternateCurrency, subType = '' } = orderPayment || {};
    const { amount: alternateCurrencyAmount = 0 } = alternateCurrency?.[currency] || {};
    const orderAmount =
      orderPaymentFromCore > 0 || (market === 'CN' && orderPaymentFromCore === 0)
        ? orderPaymentFromCore
        : currency
          ? alternateCurrencyAmount
          : amount;
    return getPaymentSourceSubType
      ? { orderPayment: orderAmount, paymentSourceSubType: subType }
      : orderAmount;
  }
  const isReservation = _get(state, 'ReviewDetails.product.isReservation', false);
  const reservationAmt = isReservation ? getReservationAmount(state) : null;
  const selectedTrim = _get(state, 'CustomGroups.TRIM.currentSelected[0]', {});
  const isNonRefundPolicy = _get(state, 'ReviewDetails.nonRefundPolicy.source', false);
  const {
    code: trimCode = '',
    order_payment_type: { type: orderTypeByTrim = '' } = {},
  } = selectedTrim;
  let paymentSourceSubType = null;

  if (reservationAmt) {
    orderPayment = Number(_get(reservationAmt, 'initial', 0));
    return getPaymentSourceSubType ? { orderPayment, paymentSourceSubType } : orderPayment;
  }
  if (!isReservation && depositAmount) {
    orderPayment = Number(depositAmount);
    return getPaymentSourceSubType ? { orderPayment, paymentSourceSubType } : orderPayment;
  }

  const pricingContext = getPricingContext(state, context);
  const baseModelPriceData = _get(state, `OMS.lexicon.options.${getModelCode(state)}.pricing`, []);

  const orderPaymentType = _get(state, 'ReviewDetails.product.isUsedInventory')
    ? NON_REFUNDABLE_ORDER_PAYMENT_USED
    : NON_REFUNDABLE_ORDER_PAYMENT;

  const orderFee = getOptionPricing(
    baseModelPriceData,
    PRICING_ORDER_FEE,
    currency || pricingContext
  );
  const orderFeeAdditive = getOptionPricing(
    baseModelPriceData,
    PRICING_ORDER_FEE_REFUNDABLE_ADDITIVE,
    currency || pricingContext
  );
  const orderPaymentTypeByTrim = orderTypeByTrim || `${PRICING_ORDER_PAYMENT}_${trimCode}`;
  const orderPaymentByTrimCode = getOptionPricing(
    baseModelPriceData,
    orderPaymentTypeByTrim,
    pricingContext
  );
  let nonRefundableOrderPayment = getOptionPricing(
    baseModelPriceData,
    orderPaymentType,
    currency || pricingContext
  );

  nonRefundableOrderPayment =
    nonRefundableOrderPayment ||
    getOptionPricing(baseModelPriceData, NON_REFUNDABLE_ORDER_PAYMENT, pricingContext);
  orderPayment = getOptionPricing(baseModelPriceData, PRICING_ORDER_PAYMENT, pricingContext);
  if (nonRefundableOrderPayment && nonRefundableOrderPayment > 0) {
    paymentSourceSubType = NON_REFUNDABLE_ORDER_PAYMENT_TYPE;
    orderPayment = nonRefundableOrderPayment;
  } else if (orderFeeAdditive && orderFeeAdditive > 0) {
    paymentSourceSubType = ORDER_FEE_REFUNDABLE_ADDITIVE_TYPE;
    orderPayment = orderFeeAdditive;
  } else if (orderFee && orderFee > 0) {
    paymentSourceSubType = ORDER_FEE_TYPE;
    orderPayment = orderFee;
  }
  if (orderPaymentByTrimCode) {
    paymentSourceSubType =
      market === 'CN' && isNonRefundPolicy ? NON_REFUNDABLE_ORDER_PAYMENT_TYPE : '';
    orderPayment = orderPaymentByTrimCode;
  }

  if (orderPaymentFromCore || (market === 'CN' && orderPaymentFromCore === 0)) {
    return getPaymentSourceSubType
      ? {
        orderPayment: orderPaymentFromCore,
        paymentSourceSubType: paymentSourceSubType,
      }
      : orderPaymentFromCore;
  }

  return getPaymentSourceSubType ? { orderPayment, paymentSourceSubType } : orderPayment;
}

/**
 * Determine the monthly or total savings amount (returns a negative number, or 0)
 * @param state
 * @returns {String}
 */
export function getSavingsAmount(state, options = { showSavingAmtWithoutMinusInFooter: false }) {
  const showSavingAmtWithoutMinus = _get(
    state,
    'FinancingOptions.showSavingAmtWithoutMinus',
    false
  );
  return getPrice(state, getFinanceType(state), {
    ...options,
    savingsAmount: true,
    showSavingAmtWithoutMinus:
      showSavingAmtWithoutMinus || options?.showSavingAmtWithoutMinusInFooter,
  });
}

/**
 * Determine is we want to show the after savings price for this finance type.
 * @param state
 * @returns {*}
 */
export function hideAfterSavingsPrice(state, context = '') {
  if (isEnterpriseOrder(state)) {
    return true;
  }
  const financeType = getFinanceType(state);
  const financeProduct = getSelectedFinanceProduct(state, { short: true });
  const financeProductId = getFinanceProductId(state);
  let hideFlag = _get(state, 'FinancingOptions.hideAfterSavingsPrice', false);
  const hideAfterSavingsPriceInFooter = _get(
    state,
    'FinancingOptions.hideAfterSavingsPriceInFooter',
    []
  );
  if (context === 'footer' && hideAfterSavingsPriceInFooter?.length) {
    hideFlag = hideAfterSavingsPriceInFooter;
  }

  if (Array.isArray(hideFlag)) {
    return (
      [financeType, financeProduct, financeProductId].filter(value => hideFlag.includes(value))
        ?.length !== 0
    );
  }

  return hideFlag;
}

/**
 * Determines if we want to show the savings amount for the current finance type.
 *
 * @param state
 * @returns {*}
 */
export function showSavingsAmount(state) {
  const financeType = getFinanceType(state);
  const financeProduct = getSelectedFinanceProduct(state, { short: true });
  const activeFinanceProducts = _get(state, 'FinancingOptions.showSavingsAmount', []);

  if (Array.isArray(activeFinanceProducts)) {
    return (
      activeFinanceProducts.indexOf(financeType) >= 0 ||
      activeFinanceProducts.indexOf(financeProduct) >= 0
    );
  }

  return activeFinanceProducts;
}

/**
 * Determines if we want to show the savings amount for the current finance type.
 *
 * @param state
 * @returns {*}
 */
export function showSavingsAmountInFooter(state) {
  if (isEnterpriseOrder(state)) {
    return false;
  }
  const financeType = getFinanceType(state);
  const activeFinanceProducts = _get(state, 'FinancingOptions.showSavingsAmountInFooter', []);

  if (Array.isArray(activeFinanceProducts)) {
    return activeFinanceProducts.indexOf(financeType) >= 0;
  }

  return activeFinanceProducts || showSavingsAmount(state);
}

/**
 * Determine if we need to hide the finance modal.
 * This is currently based on the available financing options.
 *
 * @param state
 * @returns {boolean}
 */
export function hideFinanceModal(state) {
  if (state?.SummaryPanel?.hideFinanceModal) {
    return true;
  }
  return _get(state, 'ReviewDetails.product.isReservation', false);
}
/**
 * Returns Loan Type Selector [radio options] *
 * @param state
 * @returns {Object}
 */
export function getLoanTypeOptions(state) {
  const loanType = _get(state, 'SummaryPanel.loanType', '');
  const options = [REGISTRATION_TYPE_PRIVATE, REGISTRATION_TYPE_BUSINESS].map(data => {
    return {
      type: data,
      id: `loan_type_${data}`,
      label: i18n(`FinancingOptions.finance_${data}`),
      selected: data === loanType,
    };
  });
  return options;
}

/**
 * Returns Incentives [regional, tax credit, potential savings etc.]
 * @param state
 * @returns {Object}
 */
export function getFinancialIncentives(state) {
  const { current, total } = state?.Financial?.incentives || {};
  const incentives = _get(state, 'Financial.incentives.current', {});
  const incentivesAfterDelivery = total?.incentiveAfterDelivery || 0;
  const incentiveInPurchasePrice = total?.includedInPurchasePrice || 0;
  const incentiveInDown = total?.includedInDownPayment || 0;
  let hasAfterDeliveryDisclaimer = false;
  let hasConditionDisclaimer = false;
  let hasNoConditions = false;
  const driveAwayPricewithIncentives = _get(state, 'Pricing.cash.driveAwayPricewithIncentives', 0);
  const savingsAmount = getSavingsAmount(state, { short: true });
  const potentialSavingsPrice = _get(state, 'Pricing.cash.netPrice', 0);
  const futureSavings = (total?.fuel || 0) + incentivesAfterDelivery;
  const fuelYears = getFuelYears(state);
  const { amount: regionalIncentiveInPurchasePrice = 0 } =
    getRegionalIncentiveInPurchasePrice(state) || {};
  const { amount: regionalAfterDeliveryIncentive = 0 } =
    incentives?.regional?.find(x => x?.period === 'incentiveAfterDelivery') || {};

  let incentiveOptions = {};
  if (incentives) {
    incentiveOptions = {
      taxCredit: total?.taxCredit || 0,
      regionalIncentive: _get(current, 'regional[0].amount', 0),
      regionName: _get(current, 'regional[0].longName', ''),
      fuelIncentive: _get(current, 'fuel[0].amount', 0),
      regionalFeeRebate: _get(current, 'regionalRebate[0].amount', 0),
      governmentIncentive: _get(current, 'government[0].amount', 0),
      taxCreditLease: total?.taxCreditLease || 0,
      veteranCredit: incentives?.veteranCredit?.[0]?.amount || 0,
    };
    hasAfterDeliveryDisclaimer = !_isEmpty(incentives?.regional?.find(i => i?.hasDisclaimer));
    hasConditionDisclaimer = !_isEmpty(incentives?.regional?.find(i => i?.hasConditions));
    hasNoConditions = !_isEmpty(incentives?.regional?.find(i => i?.hasNoConditions));
  }
  return {
    incentives,
    ...incentiveOptions,
    incentivesAfterDelivery,
    hasAfterDeliveryDisclaimer,
    hasConditionDisclaimer,
    driveAwayPricewithIncentives,
    savingsAmount,
    potentialSavingsPrice,
    futureSavings,
    fuelYears,
    incentiveInPurchasePrice,
    incentiveInDown,
    regionalIncentiveInPurchasePrice,
    regionalAfterDeliveryIncentive,
    hasNoConditions,
  };
}
/**
 * Returns String - Order fee disclimer if order fee is present
 * @param  {Object} state [redux state]
 * @return {String} [order fee disclaimer]
 */
export function isOrderProcessingFeeEnabled(state) {
  const baseModelPriceData = _get(state, `OMS.lexicon.options.${getModelCode(state)}.pricing`, []);
  const orderFee = getOptionPricing(baseModelPriceData, PRICING_ORDER_FEE);
  const orderFeeDisclaimer = orderFee
    ? _get(state, `OMS.lexicon.options.${getModelCode(state)}.orderProcessing_fee`, '')
    : '';
  return orderFeeDisclaimer;
}

/**
 * Returns Target Price for Inventory by market
 * @param  {Object} state [redux state]
 * @return {String} [order fee disclaimer]
 */
export function getInventoryBasePrice(state) {
  const inventoryData = _get(state, 'ReviewDetails.product.data', {});
  return parseFloat(_get(inventoryData, 'PurchasePrice', 0));
}

/**
 * Returns Inventory option pricing array
 * @param  {Object} state [redux state]
 * @return {Array} [options with pricing]
 */
export function getInventoryOptionPricing(state) {
  const falconOptionPricing = _get(state, 'ReviewDetails.product.data.OptionCodePricing', []);
  const hasConditionalPricing = getUpgradesHaveConditionalPricing(state);
  if (!hasConditionalPricing) {
    return falconOptionPricing;
  }

  const selectedUpgrades = getSelectedUpgrades(state);
  const upgradeMonroneyPricing = selectedUpgrades?.map(upgrade => upgrade.monroney) || [];

  // Create new options mapping with upgrades
  // Handle multiple upgrades by only updating return object if option not in falcon or if has conditional pricing 
  const falconOptionsMapping = falconOptionPricing?.reduce((res, option) => {
    const {code} = option;
    if (!code) {
      return res;
    }

    res[code] = option;
    return res
  }, {})

  const clonedOptionsMapping = _cloneDeep(falconOptionsMapping);
  upgradeMonroneyPricing?.forEach((upgrade) => {
    upgrade?.forEach(opt => {
      const {code, price} = opt;
      if (!code) return; 
      if (!falconOptionsMapping?.[code]) {
        clonedOptionsMapping[code] = opt;
      } else if (falconOptionsMapping?.[code]?.price !== price) {
        clonedOptionsMapping[code].price = price;
      }
    })
  })

  return Object.values(clonedOptionsMapping) || [];
}

/**
 * Returns Inventory Options prices with discount
 * @param  {Object} state [redux state]
 * @return {Number} options price with discount
 */
export function getInventoryOptionsPriceWithDiscount(state) {
  const optionsPricing = getInventoryOptionPricing(state);
  const discount = _get(state, 'ReviewDetails.product.data.Discount', 0);
  const optionsPrice = _reduce(optionsPricing, (acc, opt) => {
    const optPrice = opt?.price || 0;
    return acc + optPrice;
  }, 0);
  return optionsPrice - discount;
}

/**
 * Returns Last Day of Quarter
 * @return {Object} [last day of quarter]
 */
function getLastDayOfQuarter() {
  const date = new Date();
  const year = date.getFullYear();
  date.setHours(0, 0, 0, 0);
  const quarter = [
    new Date(year, 2, 31),
    new Date(year, 5, 30),
    new Date(year, 8, 30),
    new Date(year, 11, 31),
  ];
  if (date <= quarter[0]) return quarter[0];
  if (date <= quarter[1]) return quarter[1];
  if (date <= quarter[2]) return quarter[2];
  return quarter[3];
}

/**
 * Returns date of the day passed as an argument
 * @return {Object} next Date of day
 */
function getNextDateOfDay(dayOfWeek = 5) {
  const date = new Date();
  const day = date.getDay();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + ((dayOfWeek + 7 - day) % 7));
  return date;
}

const finplatNumberFormatConfig = {
  termLength: {
    type: 'number',
  },
  totalTerm: {
    type: 'number',
  },
  refinancingTerm: {
    type: 'number',
  },
  finalTerm: {
    type: 'number',
  },
  campaignTerm: {
    type: 'number',
  },
  termYears: {
    type: 'number',
  },
  distanceAllowed: {
    type: 'number',
  },
  residualRate: {
    type: 'percentage',
  },
  interestRate: {
    type: 'percentage',
    precision: 2,
  },
  debitRate: {
    type: 'percentage',
    precision: 2,
  },
  finalDebitRate: {
    type: 'percentage',
    precision: 2,
  },
  monthlyAdminFee: {
    type: 'currency',
  },
  startAdminFee: {
    type: 'currency',
  },
  effectiveInterestRate: {
    type: 'percentage',
    precision: 2,
  },
  ltvRatio: {
    type: 'percentage',
  },
  flatRate: {
    type: 'percentage',
    precision: 2,
  },
  annualCostRate: {
    type: 'percentage',
    precision: 2,
  },
  vatRate: {
    type: 'number',
  },
  upfrontFeeRate: {
    pass: true,
  },
  cashDownPaymentAdjustedPurchasePriceRatio: {
    type: 'number',
  },
  finalRate: {
    type: 'percentage',
    precision: 2,
  },
  finalTermInYears: {
    type: 'number',
  },
  initialTermInYears: {
    type: 'number',
  },
  notaryFee: {
    type: 'currency',
    precision: 2
  },
  userSelectedInterestRate: {
    type: 'percentage',
    precision: 2
  },
};

export const formatFinplatNumber = (key, currentNumber) => {
  const { type = 'currency', precision = 0, pass = false } = finplatNumberFormatConfig[key] || {};

  if (typeof currentNumber === 'number' && !pass) {
    switch (type) {
      case 'number': {
        return formatNumber(currentNumber, precision);
      }

      case 'percentage': {
        return (currentNumber > 0 && Number.isInteger(currentNumber * 100))
          ? formatNumber(currentNumber * 100, { precision })
          : formatNumber(currentNumber * 100, { precision: 2 });
      }

      case 'currency':
      default: {
        return formatCurrency(currentNumber, {
          precision,
        });
      }
    }
  }

  return currentNumber;
};

/**
 * This will generate the disclaimer belonging to a finance product (loan/lease).
 * It'll grab the needed variables from state and inject them in the source copy.
 *
 * @param state
 * @param financeType
 * @param priceData
 * @param financeTabId
 */
export function getDisclaimerVariables(state, { priceData = null } = {}) {
  const {
    App = {},
    Pricing = {},
    CustomGroups = {},
    Configuration = {},
    FinancingOptions = {},
    SummaryPanel = {},
    TradeIn = {},
    Forms = {},
    Financial = {}
  } = state;

  const today = new Date();
  const date = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const lastDayOfQuarter = getLastDayOfQuarter();
  const followingFriday = getNextDateOfDay(5);
  const currentDatePlus10Days = new Date(today).setDate(today.getDate() + 10);
  const { disclaimerIntlDateFormat = { dateStyle: 'long' } } = Forms;

  const { sibling, locale } = App;
  const acquisitionFee = Financial?.fees?.fees?.acquisition_fee?.[0]?.amount ?? 0;
  const commonPriceData = (
    priceData ??
    Pricing.finplat ??
    Pricing.lease ??
    Pricing.finance ??
    {}
  );

  const { grossPrice = 0 } = Pricing ?? {};
  const { inputs = {}, outputs = {} } = commonPriceData?.output ?? {};
  const term = (
    inputs?.termLength ??
    commonPriceData.leaseTerm ??
    commonPriceData.LoanAPI?.variables?.loanTerm ??
    0
  );
  const distance = (
    inputs?.distanceAllowed ??
    commonPriceData.leaseDistance ??
    commonPriceData.LoanAPI?.variables?.loanDistance ??
    0
  );

  const modelCode = Configuration.model_code ?? '';
  const selectedModel = { ms: 'Model S', mx: 'Model X', m3: 'Model 3', my: 'Model Y' }[modelCode];
  const {
    name: selectedTrim = '',
    long_name: selectedTrimLongName = selectedModel
  } = CustomGroups?.TRIM?.currentSelected?.[0] ?? {};

  const purchasePrice = _get(inputs, 'vehiclePrice', 0);

  let downPayment = (
    inputs?.cashDownPayment ??
    outputs?.calculatedDownPayment ??
    commonPriceData.downPayment?.downPaymentAmount ??
    commonPriceData.downPayment ??
    0
  );

  downPayment = downPayment < 1 ? downPayment * purchasePrice : downPayment;
  downPayment = outputs?.totalDownPayment || (downPayment + (inputs?.electricVehicleIncentive || 0));

  const downPercentageBackCalculated = Math.max((downPayment / purchasePrice) * 100, 0);
  const productId = getFinanceProductId(state);
  const tradeInInputs = TradeIn.finplatInputs?.[productId] ?? {};
  const { remainingTradInCredit, tradeInDown, additionalDownAfterTradeInCredit } =
    tradeInInputs || {};

  const disclaimerVariables = {
    CASH_PRICE: formatCurrency(grossPrice, { precision: 0 }),
    CASH_PRICE_WITHOUT_VAT: formatCurrency(commonPriceData.grossPriceWithoutVat ?? 0, { precision: 0 }),
    TERM: term,
    TERM_IN_YEARS: term / 12,
    TERM_MINUS_ONE: term - 1,
    TERM_MINUS_TWO: term - 2,
    TERM_PLUS_ONE: term + 1,
    MODEL: selectedModel,
    TRIM: selectedTrim,
    SELECTED_TRIM_LONG_NAME: selectedTrimLongName,
    EXTRA_DISCLAIMER: i18n(
      'SummaryPanel.disclaimers.applyForFinancing',
      {
        FAQ_DELIVERY_QUESTIONS: constructUrl(
          'support/frequently-asked-delivery-questions?redirect=no#info-financial',
          sibling,
          locale
        ),
      },
      null,
      {
        returnNullWhenEmpty: true,
      }
    ),
    DOWNPAYMENT_PERCENTAGE: formatNumber(downPercentageBackCalculated, { precision: 0 }),
    DOWNPAYMENT: formatCurrency(downPayment),
    DOWNPAYMENT_MONTHS: downPayment,
    MILEAGE: formatNumber(distance),
    MILEAGE_TOTAL: formatNumber((inputs?.distanceAllowed ?? 0) * (term / 12)),
    input_termLengthYears: formatNumber(term / 12, { precision: 0 }),
    FEES_ACQUISITION_FEE: formatCurrency(acquisitionFee),
    DATE: formatDate(date, locale, disclaimerIntlDateFormat),
    LAST_DAY_OF_MONTH: formatDate(lastDayOfMonth, locale, disclaimerIntlDateFormat),
    LAST_DAY_OF_QUARTER: formatDate(lastDayOfQuarter, locale, disclaimerIntlDateFormat),
    FOLLOWING_FRIDAY: formatDate(followingFriday, locale, disclaimerIntlDateFormat),
    CURRENT_DATE_PLUS_10_DAYS: formatDate(
      currentDatePlus10Days,
      locale,
      disclaimerIntlDateFormat
    ),
    TRADEIN_AMOUNT: formatCurrency(tradeInDown),
    ADDITIONAL_DOWN: formatCurrency(additionalDownAfterTradeInCredit),
    REMAINING_TRADEIN_AMOUNT: formatCurrency(remainingTradInCredit),
    COE_AMOUNT: formatCurrency(FinancingOptions.coeBidAmount ?? 0),
    VAT_PERCENT: formatPercent(getVATPercent(state), 0),
    NOTARY_FEE: formatCurrency(commonPriceData.notaryFee ?? 0, { precision: 2 }),
    PAYMENT: formatCurrency(commonPriceData.monthlyPayment ?? 0, { precision: 2 }),
    BALLON_PAYMENT: formatCurrency(getBalloonPayment(state, { context: 'form' })),
    TOTAL_AMOUNT_PAYABLE: formatCurrency(commonPriceData.totalPayable ?? commonPriceData.regional?.totalAmtPaid ?? 0, {
      precision: 2,
    }),
    AMOUNT_DUE_AT_SIGNING: formatCurrency(commonPriceData.amountDueAtSigning ?? 0),
    RESIDUAL_VALUE: formatCurrency(commonPriceData?.residualAmount ?? 0),
    FINANCED_AMOUNT: formatCurrency(commonPriceData?.financedAmount ?? 0),
    RESIDUAL_VALUE_WITHOUT_VAT: formatCurrency(commonPriceData?.residualAmountWithoutVat ?? 0),
    RATE: formatNumber(commonPriceData?.interestRate ?? 0, { precision: 2 }),
    EFFECTIVE_RATE: formatNumber(commonPriceData?.effectiveRate ?? 0, { precision: 2 }),
    ALD_DOWN_PAYMENT_PERCENT: formatNumber((commonPriceData?.aldDownPaymentPercent ?? 0) * 100),
    COST_CREDIT: formatCurrency(commonPriceData?.costOfCredit ?? 0),
    NOMINAL_INTEREST_RATE: formatNumber((commonPriceData.nominalInterestRate ?? 0) * 100, { precision: 2 }),
    SERVICE_FEE: formatCurrency(outputs?.serviecFeeValue + outputs?.bsmvOnServiceFee, {
      precision: 0,
    }),
    USER_LEASE_AMOUNT: formatCurrency(Math.min(
      SummaryPanel.userLeaseAmount ?? commonPriceData.maxFinancialAmount,
      commonPriceData.maxFinancialAmount
    ), { precision: 2 }),
    COMMISION_VALUE: formatCurrency(commonPriceData?.commisionVal ?? 0),
  };

  Object.entries({ input: inputs, output: outputs }).map(([field, data]) => {
    Object.entries(data).map(([key, value]) => {
      if (typeof value === 'number') {
        disclaimerVariables[[field, key].join('_')] = formatFinplatNumber(key, value);
      }
    });
  })

  return disclaimerVariables;
}

export function getFinancePlanDescription(state, financeType = null)
{
  const financeTypeSelected = financeType || getFinanceType(state);
  const modelCode = _get(state, 'Configuration.model_code', '');
  const maximumDescriptionLimit = 10;
  const maximumDetailLimit = 10;
  let financePlanDescription = [];
  for (let i = 0; i < maximumDescriptionLimit; i++) {
    const details = [];
    const title = i18n(`FinancingOptions.description_${financeTypeSelected}_${modelCode}.${i}.title`, null, null, {
      returnNullWhenEmpty: true
    })
    if (!title) {
      break;
    }
    for (let j = 0; j < maximumDetailLimit; j++) {
      const detail = i18n(`FinancingOptions.description_${financeTypeSelected}_${modelCode}.${i}.details.${j}`, null, null, {
        returnNullWhenEmpty: true
      })
      if(!detail){
        break;
      }
      details.push(detail);
    }
    financePlanDescription.push({
      "title" : i18n(`FinancingOptions.description_${financeTypeSelected}_${modelCode}.${i}.title`),
      "description": i18n(`FinancingOptions.description_${financeTypeSelected}_${modelCode}.${i}.description`),
      "details" : details
    })
  }
  return financePlanDescription;
}

/**
 * This will generate the disclaimer belonging to a finance product (loan/lease).
 * It'll grab the needed variables from state and inject them in the source copy.
 *
 * @param state
 * @param financeType
 * @param priceData
 * @param financeTabId
 * @param disclaimerKey
 */
export function getDisclaimer(
  state,
  { financeType = null, priceData = null, financeTabId = null, disclaimerKey = null } = {}
) {
  const financeTypeSelected = financeType || getFinanceType(state);
  const modelCode = _get(state, 'Configuration.model_code', '');
  const disclaimerVariables = getDisclaimerVariables(state, {
    financeType: financeTypeSelected,
    priceData,
  });
  const financeProductId = getFinanceProductId(state, financeTabId);
  const isUsedInventory = state?.ReviewDetails?.product?.isUsedInventory || false;
  const market = _get(state, 'OMS.oms_params.market', '');
  const showFinancePlanDisclaimer = _get(state, 'Forms.showFinancePlanDisclaimer', false);
  const showSimplifyFinancePlanDisclaimer = _get(state, 'Forms.showSimplifyFinancePlanDisclaimer', false);
  const trimCode = getTrimCode(state);

  const { language } = state?.App ?? {};
  const { disclaimers = {}, uiSettings, parameters = {} } =
    getFinanceProductData(state, financeTabId, financeTypeSelected) ?? {};
  const { useTTPDisclaimers = false } = uiSettings ?? {};

  let isInventoryKey = null;

  if (isUsedInventory) {
    isInventoryKey = 'used';
  } else if (isInventory(state)) {
    isInventoryKey = 'new';
  }

  const disclaimer = [
    disclaimers?.[language]?.[`${isInventoryKey}__${trimCode}`],
    disclaimers?.[language]?.[`${isInventoryKey}__${modelCode}`],
    disclaimers?.[language]?.[`${isInventoryKey}__default`],
    disclaimers?.[language]?.[trimCode],
    disclaimers?.[language]?.[modelCode],
    disclaimers?.[language]?.default,
  ].filter(Boolean)[0];

  if (disclaimer && !useTTPDisclaimers) {
    return i18n(disclaimer, disclaimerVariables);
  }

  switch (financeTypeSelected) {
    case FinanceTypes.LEASE: {
      if (showFinancePlanDisclaimer) {
        const type = showSimplifyFinancePlanDisclaimer ? 'simplify' : 'full';
        const key = `FinancingOptions.finance_plan_disclaimer.${type}`
        return i18n(key, disclaimerVariables , null)
      }
      return i18n('FinancingOptions.lease__disclaimer', disclaimerVariables, null, {
        specificOverride: financeProductId ? [financeProductId, modelCode] : modelCode,
        specificOverrideOperator: 'OR',
      });
    }

    case FinanceTypes.LOAN: {
      if (showFinancePlanDisclaimer) {
        const type = showSimplifyFinancePlanDisclaimer ? 'simplify' : 'full';
        const key = `FinancingOptions.finance_plan_disclaimer.${type}`
        return i18n(key, disclaimerVariables , null)
      }
      const loanData = priceData || _get(state, 'Pricing.finance', {});
      const loanType = _get(state, 'SummaryPanel.loanType', '');
      const loanDisclaimeri18nkey =
        market === 'CN' && isUsedInventory
          ? 'FinancingOptions.loan__disclaimer_for_used'
          : disclaimerKey ||
          (loanType
            ? `FinancingOptions.loan__disclaimer__${loanType}`
            : 'FinancingOptions.loan__disclaimer');

      let specificOverride = [financeProductId, modelCode, isInventoryKey];

      const is3plus7campaign = _get(
        loanData,
        'LoanAPI.variables.localeSpecific.campaign3plus7',
        null
      );
      if (Array.isArray(is3plus7campaign) && !is3plus7campaign.includes(trimCode)) {
        specificOverride = null;
      }

      return i18n(loanDisclaimeri18nkey, disclaimerVariables, null, {
        specificOverride,
        specificOverrideOperator: 'OR',
      });
    }

    case FinanceTypes.FINPLAT: {
      // Finplat uses long model codes (MDLS, MDLX,...) without the $ sign
      const longModelCode = (MODEL_OPTION[modelCode] || '').replace('$', '');
      const localDisclaimers = disclaimers.filter(
        disclaimer =>
          String(disclaimer.locale).toLowerCase() === [language, market].join('_').toLowerCase()
      );

      const productDisclaimer = localDisclaimers.some(
        ({ disclaimerText }) => disclaimerText?.[trimCode]
      )
        ? localDisclaimers.find(({ disclaimerText }) => disclaimerText?.[trimCode])
        : localDisclaimers.find(({ disclaimerText }) => disclaimerText?.[longModelCode]);

      if (productDisclaimer) {
        const disclaimerTxt =
          productDisclaimer.disclaimerText?.[trimCode] ||
          productDisclaimer.disclaimerText?.[longModelCode];

        return i18n(disclaimerTxt, disclaimerVariables);
      }

      let inventoryDisclaimerKey = null;

      if (state?.ReviewDetails?.product?.isUsedInventory) {
        inventoryDisclaimerKey = 'usedInventoryDisclaimer';
      } else if (state?.ReviewDetails?.product?.isInventory) {
        inventoryDisclaimerKey = 'newInventoryDisclaimer';
      }

      const inventoryDisclaimer = inventoryDisclaimerKey
        ? i18n(`FinancingOptions.finplat__${inventoryDisclaimerKey}`, disclaimerVariables, null, {
          specificOverride: [financeProductId, modelCode],
          specificOverrideOperator: 'OR',
          returnNullWhenEmpty: true,
        })
        : null;

      if (inventoryDisclaimer) {
        return inventoryDisclaimer;
      }

      if (parameters?.creditBasedApr) {
        return i18n('FinancingOptions.creditRatingDisclaimer');
      }

      return i18n('FinancingOptions.finplat__disclaimer', disclaimerVariables, null, {
        specificOverride: [financeProductId, modelCode],
        specificOverrideOperator: 'OR',
      });
    }

    default:
      return '';
  }
}

/**
 *
 * @param state
 * @param financeType
 * @param priceData
 */
export function getMiniDisclaimer(state = {}, { priceData = null, variables = {} } = {}) {
  const financeProductId = getFinanceProductId(state);

  if (!financeProductId) {
    return null
  }

  /** For ALD, we show a message if monthly pricing is not available */
  if (financeProductId.includes('operational_lease') && typeof state.Pricing?.lease?.monthlyPayment === 'undefined') {
    return htmlToReact(i18n('FinancingOptions.lease__undefined', null, null, {
      specificOverride: financeProductId,
    }));
  }

  const modelCode = getModel(state);
  const disclaimerVariables = getDisclaimerVariables(state, {
    priceData,
  });

  const safeVariables = typeof variables === 'object' && variables !== null ? variables : {};
  const { DOWNPAYMENT_PERCENTAGE } = disclaimerVariables || {};

  const combiedVars = { ...disclaimerVariables, ...safeVariables };

  const i18nProps = [
    combiedVars, null, {
      specificOverride: [financeProductId, modelCode],
      specificOverrideOperator: 'OR',
      returnNullWhenEmpty: true,
    }
  ];

  const miniDisclaimerFields = getDeltasByProductId(state.Forms?.showMiniDisclaimerFields, financeProductId);

  if (miniDisclaimerFields?.length) {
    const mergedLabel = miniDisclaimerFields
      .map(field => {
        // For this field, we fallback to cashDownPayment if finplat doesn't give us a monthly payments array so in that case use cashDownPayment label
        if (field === 'monthlyPaymentCount' && !combiedVars?.output_monthlyPaymentsArray?.length) {
          field = 'cashDownPayment';
        }

        return i18n(`SummaryPanel.miniDisclaimers.${field}`, ...i18nProps)
      })
      .filter(Boolean)
      // Always capitalise first letter of the first label but lowerase the ones after the comma
      .map((string, index) => string.charAt(0)[index === 0 ? 'toUpperCase' : 'toLowerCase']() + string.slice(1)).join(', ')

    if (mergedLabel) {
      return htmlToReact(mergedLabel);
    }
  }

  return htmlToReact(
    i18n(`FinancingOptions.finplat_mini_disclaimer${DOWNPAYMENT_PERCENTAGE === '0' ? '_zerodown' : ''}`, ...i18nProps)
  );
}

/**
 * Returns Boolean (nonRefundPolicy) - based on order fee if present
 * @param  {Object} state [redux state]
 * @return {Boolean} [nonRefundPolicy]
 */
export function isFeeNonRefundable(state) {
  const baseModelPriceData = _get(state, `OMS.lexicon.options.${getModelCode(state)}.pricing`, []);
  const orderFee = getOptionPricing(baseModelPriceData, PRICING_ORDER_FEE);
  return !!(orderFee && orderFee > 0);
}

/**
 * Returns transportation fee estimate
 * @param  {Object} state [redux state]
 * @param  {Object} {Latitude, Longitude}
 * @return {Number} Transportation fee
 */
export function calculateTransportationFee(
  state,
  { Latitude, Longitude, distance_from_car, metro_area, distance: distance_from_customer } = {}
) {
  const isPickupOnlyEnabled = _get(state, 'App.isPickupOnlyEnabled', false);
  if (isPickupOnlyEnabled) {
    return {};
  }

  const isDeliverySelectionEnabled = _get(state, 'App.isDeliverySelectionEnabled', false);
  const transportFees = _get(state, 'ReviewDetails.product.data.TransportFees', {});
  const isTransportFeeCollectionEnabled = _get(state, 'App.isTransportFeeCollectionEnabled');
  const { exemptVRL = [], fees = [] } = transportFees;

  let targetDistance = 0;
  let locationMetroArea = null;
  let response = null;

  if (!isDeliverySelectionEnabled) {
    const pickupLocations = _get(state, 'Payment.PickupLocations', []);
    const geoPoints = _get(state, 'ReviewDetails.product.data.geoPoints', []);
    const shortestDistance = _reduce(
      geoPoints,
      ({ distance, vrlId, vrlLat, vrlLong }, item) => {
        const key = item[0] || '';
        const vrl = item[1] || 0;
        const points = key.split(',');
        const lat = points[0] || 0;
        const lon = points[1] || 0;

        const getDistance = calculateGeoDistance(lat, lon, Latitude, Longitude);
        if (distance === null || getDistance < distance) {
          return {
            distance: getDistance,
            vrlId: vrl,
            vrlLat: lat,
            vrlLong: lon,
          };
        }
        return {
          distance,
          vrlId,
          vrlLat,
          vrlLong,
        };
      },
      { distance: null, vrlId: 0, vrlLat: 0, vrlLong: 0 }
    );
    const { distance, vrlId, vrlLat, vrlLong } = shortestDistance;
    const sourceType =
      isTransportFeeCollectionEnabled || pickupLocations.length
        ? DELIVERY_SOURCE_TRT
        : DELIVERY_SOURCE_VRL;
    response = {
      fee: 0,
      vrl: {
        id: vrlId,
        lat: vrlLat,
        lon: vrlLong,
        distance,
      },
      sourceType,
    };

    if (!distance || !vrlId || exemptVRL.includes(vrlId)) {
      return response;
    }
    // Find distance between the VRL and the closest pickup location.
    let closestTrt = { distanceToTrt: null, trtId: 0, trtLat: 0, trtLong: 0, trtMetroArea: '' };
    if (sourceType === DELIVERY_SOURCE_TRT) {
      closestTrt = _reduce(
        pickupLocations,
        ({ distanceToTrt, trtId, trtLat, trtLong, trtMetroArea }, item) => {
          // TODO: filter out by province?
          const gmapsUrl = item.gmaps_url || '';
          const key = gmapsUrl.split('=')[1] || '';
          const points = key.split(',');
          const lat = points[0] || 0;
          const lon = points[1] || 0;
          const getDistance = calculateGeoDistance(lat, lon, Latitude, Longitude);
          if (distanceToTrt === null || getDistance < distanceToTrt) {
            return {
              distanceToTrt: getDistance,
              trtId: item.trt_id,
              trtLat: lat,
              trtLong: lon,
              trtMetroArea: item.metro_area,
            };
          }
          return {
            distanceToTrt,
            trtId,
            trtLat,
            trtLong,
            trtMetroArea,
          };
        },
        closestTrt
      );
    }
    const { distanceToTrt, trtId = 0, trtLat, trtLong } = closestTrt;
    response = {
      ...response,
      trt: {
        id: trtId,
        lat: trtLat,
        long: trtLong,
        distance: distanceToTrt,
      },
    };
    let distanceVrlTrt = null;
    if (!distanceToTrt || !trtId) {
      response = {
        ...response,
        sourceType: DELIVERY_SOURCE_VRL,
      };
    } else {
      // Calculate distance between VRL lat / long & distance lat / long
      distanceVrlTrt = calculateGeoDistance(vrlLat, vrlLong, trtLat, trtLong);
    }
    targetDistance = distance;
    if (distanceVrlTrt !== null && response.sourceType === DELIVERY_SOURCE_TRT) {
      targetDistance = distanceVrlTrt;
    }
  } else {
    targetDistance = distance_from_car || distance_from_customer || 0;
    locationMetroArea = metro_area ?? null;
  }

  let fee = 0;

  const inTransit = _get(state, 'ReviewDetails.product.data.InTransit');
  const vehicleMetro = inTransit
    ? _get(state, 'ReviewDetails.product.data.InTransitSalesMetro', '')
    : _get(state, 'ReviewDetails.product.data.SalesMetro', '');
  const metroFees = _get(state, 'ReviewDetails.product.data.TransportFees.metro_fees', []);
  const metroFee = _find(
    metroFees,
    o => o.destination_metro === locationMetroArea && o.source_metro === vehicleMetro
  );

  if (metroFee) {
    fee = metroFee.amount;
  } else {
    for (let i = 0; i < fees.length; i += 1) {
      const { query = {}, amount = 0 } = fees[i];
      const { max = 0, min = 0 } = query;
      // todo: check for condition
      if (targetDistance <= max && targetDistance >= min) {
        fee = amount;
        break;
      }
    }
  }

  return {
    ...response,
    distance: targetDistance,
    fee,
  };
}

/**
 * Returns transportation fee amount
 * @param  {Object} state [redux state]
 * @return {Number} Transportation fee
 */
export function getTransportationFeeAmount(state) {
  const { App, ReviewDetails } = state;
  const { isDeliverySelectionEnabled } = App;
  const { isTransportFeeEnabled = false, DeliveryDetails, DeliveryLocations } = ReviewDetails || {};
  if (isTransportFeeEnabled) {
    const amount = isDeliverySelectionEnabled
      ? DeliveryLocations?.selected?.transportFee?.fee
      : DeliveryDetails?.TransportationFee;
    return amount || 0;
  }
  return 0;
}

/**
 * Returns show finance product on ui flag
 * @param  {Object} state [redux state]
 * @return {Boolean} showFinanceProductOnUI
 */
export function showFinanceProductOnUI(state) {
  const financeType = getSelectedFinanceProduct(state);
  return _get(state, `Pricing.${financeType}.showFinanceProductOnUI`, true);
}

/**
 * Returns vehicle upgrade/downgrade total price
 * @param  {Object} state [redux state]
 * @return {Number} vehicle upgrade/downgrade total price
 */
export function getVehicleUpgradesTotal(state) {
  const { ReviewDetails } = state;
  const { vehicleUpgrades = [] } = ReviewDetails;
  if (!vehicleUpgrades.length) {
    return 0;
  }
  return vehicleUpgrades.reduce((price, opt) => {
    const { selected = false, price: upgradePrice = 0 } = opt || {};
    if (selected) {
      price += upgradePrice;
    }
    return price;
  }, 0);
}

export function getExtraPricingContextAmounts(state) {
  const includeOrderFeeInVehicleTotal = _get(
    state,
    'ReviewDetails.includeOrderFeeInVehicleTotal',
    true
  );
  const priceContexts = _get(state, 'Pricing.calculatorResult.data.extraPriceContexts', {});
  const { alternateCurrencyCredit = {} } = getReferralCredit(state) || {};
  return Object.keys(priceContexts).reduce((acc, currency) => {
    const orderFee = getDepositAmount(state, null, CONTEXT_DEFAULT, currency);
    const { total = 0, grossPrice = 0, apiResults } = priceContexts[currency] || {};
    const { fees = {} } = apiResults || {};
    const referralCredit = alternateCurrencyCredit?.[currency] || 0;
    let totalPrice = grossPrice - referralCredit;

    if (includeOrderFeeInVehicleTotal) {
      totalPrice += orderFee;
    }

    acc.push({
      currency,
      subTotal: total,
      grossPrice,
      vatAmount: fees?.current?.vat_percent?.total || 0,
      totalPrice,
      fees: Object.entries(fees.current || {}).reduce(
        (feesObj, [fee, { total }]) => ({ ...feesObj, [fee]: total }),
        {}
      ),
    });
    return acc;
  }, []);
}

/**
 * Returns Price after EV Credit
 * @param  {Object} state [redux state]
 * @return {Number} Price after EV Credit
 */
export function getPriceAfterEVCredit(state) {
  const { Pricing } = state;
  const { grossPrice } = Pricing || {};
  const governmentIncentive =
    Pricing?.calculatorResult?.data?.apiResults?.incentives?.current?.government?.total || 0;
  const EVCredit = Math.abs(governmentIncentive);
  return grossPrice - EVCredit;
}

/**
 * return product type
 */
export function getProductTypeMapping(state) {
  const financeType = getFinanceType(state);
  const productId = getFinanceProductId(state);
  const productType = productId ? productId.split(':')[0] : '';
  return productType ? FinanceTypes[productType] : financeType;
}

/**
 * return regional fee rebate amount
 */
export function getRegionalFeeRebate(state) {
  return _get(state, 'Financial.incentives.current.regionalRebate[0].amount', 0);
}

/**
 * return currencies actually used in MO for MOP and HKD
 */
export function getAmountInOtherCurrencies(state, modelOption) {
  const baseCurrency = state?.Payment?.CurrencyCode;
  const contextMapping = _get(state, 'OMS.lexicon.metadata.pricing.context_mapping', {});
  const allowedCurrencies = Object.values(contextMapping);
  const currencies = _get(state, `OMS.lexicon.options.${modelOption}.pricing`, []).reduce(
    (currencies, { context: currencyContext }) => {
      if (allowedCurrencies.includes(currencyContext) && !currencies.includes(currencyContext)) {
        currencies.push({
          currency: currencyContext,
          context: getKeyByValue(contextMapping, currencyContext) || CONTEXT_DEFAULT,
        });
      }
      return currencies;
    },
    []
  );
  const amountInOtherCurrencies = currencies.reduce(
    (extraCurrencies, { currency, context }) =>
      currency !== baseCurrency
        ? [
          ...extraCurrencies,
          {
            amount: getDepositAmount(state, null, context, currency),
            currency,
          },
        ]
        : extraCurrencies,
    []
  );
  return amountInOtherCurrencies;
}
/**
 * return amounts in other currencies actually used in MO for MOP and HKD
 */
export function getExtraCurrencyAmount(state, modelOption) {
  const { ReviewDetails } = state;
  const { showOrderFeeInOtherCurrency = true, product } = ReviewDetails;
  if (!showOrderFeeInOtherCurrency) {
    return '';
  }
  if (product?.isReservation) {
    const depositAmountPerCurrency = _get(state, 'Payment.depositAmountPerCurrency', {});
    const secondaryCurrency = _get(depositAmountPerCurrency, 'currency_secondary');
    const paymentAmtSecondary = secondaryCurrency
      ? _get(depositAmountPerCurrency, secondaryCurrency, {})
      : null;
    return paymentAmtSecondary
      ? formatCurrency(paymentAmtSecondary.initial, { currency: secondaryCurrency })
      : '';
  }
  const amountInOtherCurrencies = getAmountInOtherCurrencies(state, modelOption);
  return amountInOtherCurrencies.length
    ? amountInOtherCurrencies.map(({ currency, amount }) =>
      formatCurrency(amount, {
        currency,
      })
    )[0]
    : '';
}

/**
 * Return filtered menu items for finance modal
 */
export function getEnabledTabs(state, app_state) {
  const { menuItems, activeTabs } = state;
  // TODO: Remove delivery_timing from menuItems in InitialConfig in rest-ds-services
  const filteredItems = menuItems.filter(item => {
    const { key, hide, content_path } = item || {};
    const showContent = _get(app_state, content_path, true);
    return key !== 'delivery_timing' && activeTabs.includes(key) && !hide && showContent;
  });

  return filteredItems;
}

/**
 * Return a bool if delivery incentive is available
 */
export function hasDeliveryIncentives(state) {
  const deliveryIncentives = {
    beforeDelivery: _get(state, 'Financial.incentives.current.beforeDelivery', []),
    afterDelivery: _get(state, 'Financial.incentives.current.afterDelivery', []),
  };
  const { beforeDelivery = [], afterDelivery = [] } = deliveryIncentives;
  return beforeDelivery?.some(x => x?.amount) || afterDelivery?.some(x => x?.amount);
}

/**
 * Return air conditioning fee
 */
export function getAcquisitionFee(state) {
  return _get(state, 'Financial.fees.current.acquisition_fee[0].amount', 0);
}

/**
 * Return tire fee
 */
export function getRegionalTireFee(state) {
  return _get(state, 'Financial.fees.current.regional_tire_fee[0].amount', 0);
}

/**
 * Return inspection fee
 */
export function getInspectionFee(state) {
  return _get(state, 'Financial.fees.current.inspection_fee[0].amount', 0);
}

/**
 * Return luxury tax
 */
export function getLuxuryTax(state) {
  return _get(state, 'Financial.fees.current.luxury_tax[0].amount', 0);
}

/**
 * Return total fees
 */
export function getFeesTotal(state) {
  const { fees } = state?.Financial || {};
  return _get(fees, 'total.once', 0);
}

/**
 * Return total fees
 */
export function getAllCurrentFees(state) {
  return _get(state, 'Financial.fees.current', {});
}

/**
 * Get currently selected finance product type.
 * Possible values (not exhaustive):
 *   loan, lease, auto_lease, auto_loan.
 *
 * @return {String}
 */
export function getFinanceProductType(state) {
  const productId = getFinanceProductId(state);
  const id = productId ? productId.split(':')[0] : '';
  return FinanceTypes?.[id] || id || null;
}

/**
 * Return registrattion tax
 */
export function getRegistrationTax(state) {
  return _get(state, 'Pricing.calculatorResult.data.variables.fees.registration.total', 0);
}

/**
 * Return vehicle price with discounts
 */
export function getVehiclePriceWithDiscounts(state) {
  return _get(state, 'Pricing.cash.vehiclePriceWithoutDiscounts', 0);
}

/**
 * Return delivery fee
 */
export function getDeliveryFee(state) {
  return _get(state, 'Pricing.calculatorResult.data.variables.fees.delivery_fee.total', 0);
}

/**
 * Return a flag for generic other fees disclaimer source
 */
export function getGenericOtherFeesDisclaimerSource(state) {
  return _get(state, 'ReviewDetails.genericOtherFeeDisclaimer');
}

/**
 * Return regional incentive in purchase price
 */
export function getRegionalIncentiveInPurchasePrice(state) {
  const incentive = _get(state, 'Financial.incentives.current.regional', []);
  return (
    incentive?.find(x => ['includedInPurchasePrice', 'includedInDownPayment'].includes(x.period)) ||
    {}
  );
}

/**
 * Return fees discalimer
 */
export function getFeesDisclaimer(state) {
  const { formattedPrices, region_code: region } = state.SummaryPanel || {};
  const countryCode = state?.App?.countryCode || '';
  const { showOrderFee } = state.FinancingOptions || {};
  const docFee = getDestinationAndDocFee(state);
  const luxuryTax = getLuxuryTax(state);
  const omvicFee = getInspectionFee(state);
  const acFee = getAcquisitionFee(state);
  const productType = getFinanceProductType(state);
  const productId = getFinanceProductId(state);
  const formattedDocFee = formatCurrency(docFee);
  const vehiclePriceWithoutDiscounts = getVehiclePriceWithDiscounts(state);
  const registrationTax = getRegistrationTax(state);
  const orderFeeAmount = showOrderFee ? getDepositAmount(state) : 0;
  const deliveryFee = getDeliveryFee(state);
  const genericOtherFeesDisclaimerSource = getGenericOtherFeesDisclaimerSource(state);
  const govtCredit = getGovernmentIncentives(state);
  const { amount: regionalIncentiveInPurchasePrice, longName: regionName } =
    getRegionalIncentiveInPurchasePrice(state) || {};
  const incentiveInDown = getDownPaymentIncentive(state);
  const isUsed = isUsedInventory(state);
  const tireFee = getRegionalTireFee(state);

  const disclaimerVariables = {
    DOC_FEE: formattedDocFee,
    AC_FEE: formatCurrency(acFee),
    AC_FEE_DISCLAIMER: acFee
      ? ', '.concat(
        i18n('SummaryPanel.disclaimers.airConditioningFee', {
          acFee: formatCurrency(acFee),
        })
      )
      : null,
    OMVIC_FEE: formatCurrency(omvicFee, { useDynamicRounding: true }),
    OMVIC_FEE_DISCLAIMER: omvicFee
      ? ', '.concat(
        i18n('SummaryPanel.disclaimers.inspectionFeeDisclaimer__ON', {
          OMVIC_FEE: formatCurrency(omvicFee, { useDynamicRounding: true }),
        })
      )
      : null,
    DESTINATION_AND_DOC_FEE: formattedDocFee,
    DOC_FEE_DISCLAIMER: docFee
      ? i18n('SummaryPanel.disclaimers.destinationFee', {
        DOC_FEE: formattedDocFee,
      }).concat(', ')
      : null,
    LUXURY_TAX_DISCLAIMER: luxuryTax
      ? ', '.concat(
        i18n('SummaryPanel.disclaimers.federalLuxuryTax', {
          LUXURY_TAX: formatCurrency(luxuryTax, { useDynamicRounding: true }),
        })
      )
      : null,
    RECYCLING_FEE: formattedPrices?.recyclingFee,
    TIRE_FEE: tireFee ? formattedPrices?.tireFee || formatCurrency(tireFee) : null,
    TIRE_FEE_DISCLAIMER: tireFee
      ? i18n('SummaryPanel.disclaimers.tireFeeDisclaimer', {
        TIRE_FEE: formattedPrices?.tireFee || formatCurrency(tireFee),
      })
      : null,
    WINTER_TIRE_FEE: formattedPrices?.winterTireFee,
    FRT_FEE: formattedPrices?.firstRegistrationTax,
    CASH_PRICE: formattedPrices?.vehiclePrice,
    VEHICLE_PRICE_WITHOUT_DISCOUNTS: formatCurrency(vehiclePriceWithoutDiscounts),
    DELIVERY_FEE: formatCurrency(deliveryFee),
    REGISTRATION_TAX: formatCurrency(registrationTax),
    PURCHASE_TAX: formattedPrices?.purchaseTax,
    ORDER_FEE: formatCurrency(orderFeeAmount),
    GOVT_INCENTIVE: govtCredit
      ? i18n('SummaryPanel.disclaimers.govtCredit', {
        AMOUNT: formatCurrency(Math.abs(govtCredit)),
      }).concat(', ')
      : null,
    DOWNPAYMENT_INCENTIVE: incentiveInDown
      ? i18n(
        'SummaryPanel.disclaimers.downPaymentIncentive',
        {
          CLASS: 'tds--vertical_padding--small',
          REGION: regionName,
        },
        null,
        {
          specificOverride: regionalIncentiveInPurchasePrice ? 'regional' : null,
          returnNullWhenEmpty: true,
          specificOverrideOperator: 'OR',
        }
      )
      : null,
    GOVT_INCENTIVE_DISCLAIMER: regionalIncentiveInPurchasePrice
      ? i18n('SummaryPanel.disclaimers.govtCreditDisclaimer__regional', {
        REGIONAL_INCENTIVE: formatCurrency(Math.abs(regionalIncentiveInPurchasePrice)),
        REGION: regionName,
      })
      : null,
    REGIONAL_INCENTIVE: regionalIncentiveInPurchasePrice
      ? i18n('SummaryPanel.disclaimers.regionalIncentiveInPurchasePrice', {
        AMOUNT: formatCurrency(Math.abs(regionalIncentiveInPurchasePrice)),
        REGION: regionName,
      }).concat(', ')
      : null,
  };

  if (genericOtherFeesDisclaimerSource && _isArray(genericOtherFeesDisclaimerSource)) {
    // attempt to determine if other fees generic disclaimer should be used based on the fee availability
    const shouldUseGeneric = genericOtherFeesDisclaimerSource.filter(i => !!disclaimerVariables[i]);
    if (!shouldUseGeneric.length) {
      return i18n('SummaryPanel.disclaimers.otherFees__generic');
    }
  }

  if (isUsed && regionalIncentiveInPurchasePrice) {
    return i18n(
      'SummaryPanel.disclaimers.inventoryRegionalIncentive',
      {
        AMOUNT: formatCurrency(Math.abs(regionalIncentiveInPurchasePrice)),
        REGION: regionName,
      },
      null,
      {
        specificOverride: [productType],
        returnNullWhenEmpty: true,
        specificOverrideOperator: 'OR',
      }
    );
  }

  const otherFees = i18n('SummaryPanel.disclaimers.otherFees', disclaimerVariables, null, {
    specificOverride: [productType, region],
    returnNullWhenEmpty: true,
    specificOverrideOperator: 'OR',
  });

  const monthlyPaymentDisclaimer = i18n('FinancingOptions.unsecuredLoanMonthlyPaymentDisclaimer', null, null, {
    specificOverride: productId,
    returnNullWhenEmpty: true,
  });

  if (otherFees) {
    return otherFees;
  }

  if(monthlyPaymentDisclaimer) {
    return monthlyPaymentDisclaimer;
  }

  if (docFee && !['US', 'PR'].includes(countryCode)) {
    return i18n('SummaryPanel.disclaimers.includingDestinationAndDocFee', disclaimerVariables);
  }
  return '';
}

/**
 * Return total fees
 */
export function getTotalAcquisitionFee(state) {
  return getAcquisitionFee(state) + getInspectionFee(state) + getRegionalTireFee(state);
}

/**
 * Return Currency code
 */
export function getCurrencyCode(state) {
  const { App, Payment } = state;
  const { pricingContext } = App;
  const { CurrencyCode = 'USD' } = Payment;
  return pricingContext !== 'default' ? pricingContext : CurrencyCode;
}

/**
 * Return Additional amounts to be added to gross price
 */
export function getAddOnToGrossPrice(state) {
  let result = 0;
  const { FinancingOptions, App } = state;
  const { showOrderFee = false, coeBidAmount } = FinancingOptions;
  const { showSavingsAfterTaxCredit = false } = App || {};
  if (showOrderFee || showSavingsAfterTaxCredit) {
    result += getDepositAmount(state);
  }
  if (coeBidAmount) {
    result += coeBidAmount;
  }

  return result;
}

/**
 * Return price excluding coe
 */
export function getPriceExcludingCoE(state) {
  const { FinancingOptions, Pricing } = state;
  const { coeBidAmount, showCoEBidInput } = FinancingOptions;
  const { grossPrice } = Pricing;
  const result = grossPrice - coeBidAmount || 0;
  return showCoEBidInput ? formatCurrency(result) : null;
}

/**
 * Returns months after first registration
 */
export function getRegistrationAge({ ReviewDetails, FinancingOptions }) {
  const firstRegistered = _get(ReviewDetails, 'product.data.FirstRegistrationDate');
  const useRegistrationAge = _get(FinancingOptions, 'useRegistrationAge', []);
  const maxAgeForFinancing = _get(FinancingOptions, 'demoCarFinancing.maxAge', null);
  if ((useRegistrationAge.length || maxAgeForFinancing) && firstRegistered) {
    const registrationDate = new Date(firstRegistered);
    const currentDate = new Date();
    const ageInMonths =
      currentDate.getMonth() -
      registrationDate.getMonth() +
      12 * (currentDate.getFullYear() - registrationDate.getFullYear());
    return Math.max(1, ageInMonths);
  }
}

/**
 * Filter finance tabs
 */
export function filterFinanceTabs(state, tabs = []) {
  const useRegistrationAge = _get(state, 'FinancingOptions.useRegistrationAge', []);
  const { limitProductByPrice = {}, hideFinancingForMarginCars = [] } =
    state.FinancingOptions ?? {};
  const registrationAge = getRegistrationAge(state);
  const { grossPrice } = state.Pricing ?? {};
  return tabs.filter(tab => {
    if (tab !== FinanceTypes.CASH && !isUsedInventory(state)) {
      const Odometer = _get(state, 'ReviewDetails.product.data.Odometer', null);
      const maxAgeForFinancing = _get(state, 'FinancingOptions.demoCarFinancing.maxAge', null);
      const maxOdometerForFinancing = _get(
        state,
        'FinancingOptions.demoCarFinancing.maxOdometer',
        null
      );
      if (Odometer && maxOdometerForFinancing && Odometer > maxOdometerForFinancing) {
        return false;
      }
      if (registrationAge && maxAgeForFinancing && registrationAge > maxAgeForFinancing) {
        return false;
      }
    }

    if (limitProductByPrice[tab] && grossPrice > limitProductByPrice[tab]) {
      return false;
    }

    if (hideFinancingForMarginCars.includes(tab) && getTradeInType(state) === 'margin') {
      return false;
    }

    if (useRegistrationAge.includes(tab)) {
      const financeType = getFinanceType(state, tab);
      const financeProductId = getFinanceProductId(state, tab);
      const productData = getFinanceProductData(state, financeProductId, financeType);
      const limitTermsByAge = _get(productData, `variables.localeSpecific.limitTermsByAge`, false);
      const termsKey = {
        loan: 'loanTermsOffered.loanTerm',
        lease: 'leaseTermsOffered.leaseTerm',
      };
      const minimumTerm = limitTermsByAge
        ? Math.min(..._get(productData, termsKey[financeType], []))
        : 0;
      const maxAge = _get(productData, `variables.localeSpecific.maxAge`, null);
      return !(!registrationAge || (maxAge && maxAge < minimumTerm + registrationAge));
    }
    return true;
  });
}

/**
 * Get Delivery Credit
 */
export const getDeliveryCredit = state => {
  return _get(state, 'Financial.incentives.total.credit', 0);
};

/**
 * Get Monthly Payment with Delivery Credit
 */
export const getMonthyPaymentWithCredit = state => {
  const total = _get(state, 'Pricing.finplat.output.outputs.monthyPaymentWithCredit', 0);
  return formatMonthlyPrice(total);
};

/**
 * Get Tax Credit
 */
export const getTaxCredit = state => {
  return _get(state, 'Financial.incentives.total.taxCredit', 0);
};

/**
 * Get Tax Credit current available
 */
export const getTaxCreditCurrentAvailable = state => {
  return _get(state, 'Financial.incentives.currentAvailable.taxCredit[0].amount', 0);
};

/**
 * Get Tax Credit with Fallback
 */
export const getTaxCreditWithFallback = state => {
  return getTaxCredit(state) || _get(state, 'Financial.incentives.total.taxCreditLease', 0);
};

/**
 * Get Tax Credit Amount
 */
export const getCurrentTaxCredit = state => {
  return _get(state, 'Financial.incentives.current.taxCredit[0].amount');
};

/**
 * Get Tax Credit Amount for Trim Code
 */
export const getTaxCreditForTrim = (state, optionCode) => {
  const trimPricing = _get(
    state,
    `Pricing.calculatorResult.data.apiResultsPerTrim.${optionCode}`,
    {}
  );
  return (
    trimPricing?.incentives?.total?.taxCredit || trimPricing?.incentives?.total?.taxCreditLease || 0
  );
};

/**
 * Get PurchasePrice Incentive
 */
export const getPurchasePriceIncentive = state => {
  return _get(state, 'Financial.incentives.total.includedInPurchasePrice', 0);
};

/**
 * Get DownPayment Incentive
 */
export const getDownPaymentIncentive = state => {
  return _get(state, 'Financial.incentives.total.includedInDownPayment', 0);
};

/**
 * Get Tax Credit Amount with Regional Incentives Amount
 */
export const getTaxCreditWithRegional = state => {
  const taxCredit = Math.abs(getTaxCreditWithFallback(state) || 0);
  const regionalAmount = Math.abs(getRegionalIncentives(state) || 0);
  return taxCredit + regionalAmount;
};

/**
 * Get Tax Credit Amount with Regional Incentives Amount for base (no conditions)
 */
export const getTaxCreditWithRegionalForBase = state => {
  const taxCredit = Math.abs(getTaxCreditWithFallback(state) || 0);
  const regionalAmount = Math.abs(getRegionalIncentivesForBase(state) || 0);
  return taxCredit + regionalAmount;
};

/**
 * Get Incentive eligibility disclaimer
 */
export const getIncentiveEligibilityDisclaimerForDesign = state => {
  const { current = {} } = state?.Financial?.incentives || {};
  let variables = {};
  const overrides = [];

  Object.keys(current)?.forEach(itm => {
    const [incentive] = current?.[itm] || {};
    const { amount, showEligibilityCheck: showCheck, incentiveType, longName = '' } =
      incentive || {};
    if (showCheck && amount) {
      const key = incentiveType?.toUpperCase();
      overrides.push(incentiveType);
      variables = {
        ...variables,
        [`${key}_INCENTIVE`]: i18n(`Incentives.${incentiveType}`, {
          AMOUNT: formatCurrency(Math.abs(amount)),
          REGION: longName,
        }),
      };
    }
  });

  return i18n('Incentives.designEligibility', variables, null, {
    specificOverride: overrides,
    returnNullWhenEmpty: true,
    specificOverrideOperator: 'OR',
  });
};

export const getReferralCredit = state => {
  const { includeReferralCreditInFinance = [] } = state?.FinancingOptions || {};
  const { selected_tab: tab } = state?.SummaryPanel || {};
  const selectedTab = tab?.split('.')?.[0] || '';
  const { referral } = state?.ApplicationFlow || {};
  const credit = referral?.cash?.value || 0;
  const { currency = '', value = 0 } = referral?.cash?.secondary_currency || {};

  const defaultCreditExcludedCopy =  i18n('SummaryPanel.disclaimers.referralCreditExlcuded', {
    AMOUNT: formatCurrency(credit),
  });

  const creditExcludedCopy =  i18n('Referral.overrides.referralCreditExcluded', { AMOUNT: formatCurrency(credit) }, null, { returnNullWhenEmpty: true }) ?? defaultCreditExcludedCopy;

  return {
    credit,
    alternateCurrencyCredit: currency
      ? {
        [currency]: value,
      }
      : {},
    includedInPayments: (includeReferralCreditInFinance || [])?.includes(selectedTab) || state?.App?.isCoinReloaded,
    financeDisclaimer: i18n('Referral.finance_disclaimer', {
      AMOUNT: formatCurrency(credit),
    }),
    creditExcludedCopy: !state?.Pricing?.lease?.isALD && creditExcludedCopy,
  };
};

export const getIncentivesCopy = state => {
  const disclaimer = [];
  const incentive = state?.Financial?.incentives?.current || [];
  Object.keys(incentive).map(key => {
    const { copy } = incentive?.[key]?.find(x => x?.copy) || {};
    if (copy) {
      const { source, path = {} } = copy || {};
      disclaimer.push({
        title: _get(state, `${source}.${path?.title}`, ''),
        description: _get(state, `${source}.${path?.description}`, ''),
      });
    }
  });
  return disclaimer;
};

export const getLeaseDownPayment = state => {
  let { cashDownPayment: down = 0 } = state?.Pricing?.finplat?.userInput || {};
  if (!down) {
    const { fms_finplat = {} } = state.Financial || {};
    Object.keys(fms_finplat).forEach(itm => {
      if (itm.startsWith(AUTO_LEASE)) {
        const { products = [] } = fms_finplat[itm] || {};
        const { defaultDownPayment } = products?.find(x => x?.isDefault) || {};
        down = defaultDownPayment;
      }
    });
  }
  return down;
};

export const getTaxesAndFees = state => {
  const { taxesAndFees = {}, includeTaxesAndFees = false } = state.FinancingOptions || {};
  if (!includeTaxesAndFees) {
    return {};
  }
  const { totalUpfrontTax = 0 } = state.Pricing?.finplat?.output?.outputs || {};
  const { salesTax = 0 } = state.Pricing?.cash || {};
  const selectedTab = getProductTypeMapping(state)?.toUpperCase() || '';
  const { taxes = [], fees = [] } = taxesAndFees?.[`${PRODUCT_TYPE?.[selectedTab]}`] || {};
  const depositAmount = getDepositAmount(state);
  const teslaFees = [];
  const nonTeslaFees = [];
  const credit = [];
  let dispositionFee = 0;

  fees?.forEach(fee => {
    if (fee?.feeCategory === TESLA_FEE) {
      const amount = fee?.code === FEE_CODES[ORDER_FEE_TYPE] ? depositAmount : fee?.amount;
      teslaFees.push({ ...fee, amount });
    }
    if (fee?.feeCategory === NON_TESLA_FEE) {
      nonTeslaFees.push(fee);
    }
    if (fee?.code === 'EVI012' && fee?.sfdcFieldName === 'Lease Credit') {
      credit.push(fee);
    }
    if (fee?.code === 'DIS001') {
      dispositionFee = fee?.amount;
    }
  });

  return {
    taxes,
    fees: [].concat(teslaFees, nonTeslaFees, credit),
    teslaFees,
    nonTeslaFees,
    salesTax,
    totalUpfrontTax,
    dispositionFee,
  };
};

export const getFuelYearOptions = () => {
  const result = [];
  for (let i = 1; i <= 10; i++) {
    result.push({ label: i, value: i });
  }
  return result;
};

export const getInVehicleUnitFee = state => {
  const { fees } = state?.Pricing?.calculatorResult?.data?.apiResults || {};
  return fees?.current?.in_vehicle_unit?.total || 0;
};

export const getFinanceProductToHide = state => {
  const { hideFinanceProductOnDiscount = [] } = state?.FinancingOptions;
  if (!hideFinanceProductOnDiscount?.length) {
    return false;
  }
  const { vehicleDesign: { swapConfig = {} } = {}, product = {} } = state?.ReviewDetails;
  const productId = getFinanceProductId(state);
  const productType = productId ? productId.split(':')[0] : '';
  const vehicleDiscount = swapConfig?.Discount || product?.data?.Discount || 0;
  return !!(hideFinanceProductOnDiscount?.includes(productType) && vehicleDiscount);
};

/**
 * Return reservation credit
 */
export function getReservationCredit(state) {
  const { isReservationToOrderFlow: flag = false } = state?.ApplicationFlow || {};
  const amount = _get(state, 'ApplicationFlow.reservationAmountReceived', 0);
  return flag && amount ? -amount : 0;
}

export const getDefaultDownPaymentOverride = (state, financeProductId) => {
  const { tradeInAmount = 0 } = state?.TradeIn || {};
  const { defaultDown = [] } = state?.FinancingOptions || {};
  const { fms_finplat: finplat } = state?.Financial || {};
  let downPayment = null;
  if (defaultDown?.length) {
    const region = getUserRegionCode(state);
    const productId = getFinanceProductId(state);
    const productType = productId ? productId.split(':')[0] : '';
    downPayment = defaultDown.reduce((res, itm) => {
      const { productType: pType = [], regionCodes = [], amount } = itm || {};
      if (pType?.length && pType?.includes(productType)) {
        res = amount;
        if (regionCodes?.length) {
          res = regionCodes.includes(region) ? amount : null;
        }
      }
      return res;
    }, null);
  }
  if (tradeInAmount > 0) {
    const { defaultDownPayment = 0 } = finplat?.[financeProductId]?.parameters || {};
    const { financeType, amount } = state?.Financial?.fees?.fees?.ev_incentive?.[0] || {};
    const productType = financeProductId ? financeProductId.split(':')[0] : '';
    const incentive = FinanceTypes[productType] === financeType ? amount : 0;
    const tradeinDown = tradeInAmount + incentive;
    downPayment = tradeinDown < defaultDownPayment ? null : tradeinDown;
  }
  return downPayment !== null ? { cashDownPayment: downPayment } : {};
};

export const showPreQualifyEntryOnFinanceTabs = state => {
  const { App: { isPreQualifyEnabled } = {}, SummaryPanel: { selected_tab: tab } = {} } =
    state || {};
  return !!(isPreQualifyEnabled && tab !== FinanceTypes.CASH);
};

/**
 * Return Order fee from financial svc
 */
export function getOrderFeeFromFinancialSvc(state) {
  return _get(state, 'Financial.fees.current.order_fee[0].amount', 0);
}

export const getMonthlyPaymentAfterTaxCredit = state => {
  return _get(state, 'Pricing.finplat.output.outputs.monthlyPaymentAfterTaxCredit', 0);
};

/**
 * Get first month disclaimer
 */
export const getFirstMonthDisclaimer = state => {
  const modelCode = getModel(state);
  const currentFinanceType = getFinanceType(state);
  const financeProductId = getFinanceProductId(state);
  const { MODEL_3, MODEL_S, MODEL_X, MODEL_Y } = Models || {};
  const { FINPLAT } = FinanceTypes || {};
  if (
    currentFinanceType !== FINPLAT ||
    !financeProductId.startsWith('AUTO_LOAN:') ||
    ![MODEL_3, MODEL_S, MODEL_X, MODEL_Y].includes(modelCode)
  ) {
    return '';
  }
  return i18n(
    'SummaryPanel.disclaimers.firstMonthDisclaimer',
    { MODEL: getModelName(state) },
    null,
    {
      returnNullWhenEmpty: true,
    }
  );
};

/**
 * Check if leasing is unavailable in a given state
 * @param state
 *
 * @return bool
 */
export const isLeasingUnavailableInState = state => {
  const { App, SummaryPanel } = state;
  const { uiCountry } = App;
  const { region_code: regionCode } = SummaryPanel;
  if (uiCountry !== 'US') {
    return false;
  }
  return regionCode && !LEASING_STATES_LIST.includes(regionCode);
};

export const getFinplatInputsWithTradeIn = state => {
  const { tradeInAmount: tradeInValue = 0, tradeInCredit } = state?.TradeIn || {};
  const { finplat } = state?.Pricing || {};
  const { isTradeInEnabled } = state?.App || {};
  const { outputs = {}, inputs: { cashDownPayment = 0, electricVehicleIncentive = 0 } = {} } =
    finplat?.output || {};
  if (!finplat || !isTradeInEnabled || !tradeInCredit) {
    return;
  }
  const maxCashDownPayment = Math.floor(outputs?.maxCashDownPayment || 0);
  const minCashDownPayment = Math.floor(outputs?.minCashDownPayment || 0);

  const tradeInAmount = tradeInValue + (electricVehicleIncentive || 0);
  const remainingTradInCredit =
    tradeInAmount > maxCashDownPayment ? tradeInAmount - maxCashDownPayment : 0;
  const tradeInDown =
    (remainingTradInCredit ? maxCashDownPayment : tradeInAmount) - electricVehicleIncentive;

  const result = {
    minCashDownPayment,
    remainingTradInCredit,
    cashDownPayment,
    tradeInDown,
    additionalDownAfterTradeInCredit: cashDownPayment - tradeInAmount,
  };

  if (tradeInValue >= 0) {
    if (tradeInAmount >= cashDownPayment && tradeInAmount <= maxCashDownPayment) {
      return {
        ...result,
        cashDownPayment: tradeInAmount,
        minCashDownPayment: tradeInAmount,
      };
    } else if (tradeInAmount >= cashDownPayment && tradeInAmount >= maxCashDownPayment) {
      return {
        ...result,
        cashDownPayment: maxCashDownPayment,
        minCashDownPayment: maxCashDownPayment,
      };
    } else if (tradeInAmount < cashDownPayment) {
      return {
        ...result,
        minCashDownPayment: minCashDownPayment < tradeInAmount ? tradeInAmount : minCashDownPayment,
      };
    }
  } else {
    return result;
  }
};

export const getTradeInDisclaimers = state => {
  const { TradeIn } = state || {};
  const productId = getFinanceProductId(state);
  const productType = getFinanceProductType(state);
  const tradeInInputs = _get(TradeIn, `finplatInputs[${productId}]`, {});
  const { remainingTradInCredit, tradeInDown, additionalDownAfterTradeInCredit } =
    tradeInInputs || {};
  const discalimerVariables = getDisclaimerVariables(state) || {};
  let downPayment = null;
  let dueAtSigning = null;
  let monthlyPayment = null;
  let remainingCredit = null;

  const tradeinKey =
    additionalDownAfterTradeInCredit > 0 ? 'credit_applied_plus_additional_down' : 'credit_applied';

  if (tradeInDown > 0) {
    downPayment = i18n(`TradeIn.downpayment.${tradeinKey}`, discalimerVariables);
    dueAtSigning = i18n(
      `TradeIn.tradein_dueAtSign_disclaimer.${productType}.${tradeinKey}`,
      discalimerVariables
    );
    monthlyPayment = i18n('TradeIn.downpayment.credit_applied_with_action', {
      ...discalimerVariables,
      TRIGGER: SET_FINANCE_MODAL_TAB_TRADEIN,
    });
    remainingCredit =
      remainingTradInCredit > 0
        ? i18n('TradeIn.downpayment.credit_remaining', discalimerVariables)
        : null;
  }

  return {
    downPayment,
    dueAtSigning,
    monthlyPayment,
    remainingCredit,
  };
};

export const getDefaultFuelMonths = state => {
  return _get(
    state,
    'Financial.fms_tcc.tcc[0].variables.months',
    _get(state, 'Financial.fms_incentives.incentives[0].variables.months')
  );
};

export const getFuelMonthsByFinanceType = state => {
  const { fuelMonths } = state?.SummaryPanel || {};
  return fuelMonths || getDefaultFuelMonths(state);
};

export const getMonthlyFuelSavings = state => {
  const { fuel = 0 } = state?.Financial?.incentives?.total || {};
  const months = getFuelMonths(state);
  return Math.round(fuel / months) || 0;
};

export const getDeltasByProductId = (obj = {}, productId) => {
  if (!productId) {
    return [];
  }

  const [productType = '', productSubType = ''] = `${productId}`.split(':');

  return Array.isArray(obj)
    ? obj
    : obj?.[productId] ??
    obj?.[`${productType}:${productSubType}`] ??
    obj?.[productSubType] ??
    obj?.[productType] ??
    obj?.default ??
    [];
}

export const getFinplatFormFields = ({ Forms = {} }, productId) => getDeltasByProductId(Forms?.showFinplatFields ?? {}, productId);


export const getEligibleIncentives = (state, { incentives: inputs = [] } = {}) => {
  const current = state?.Financial?.incentives?.currentAvailable || {};
  let incentives = {};

  Object.keys(current).forEach(item => {
    const currentSelected = current?.[item];
    if (currentSelected?.length) {
      const [incentive] = currentSelected || {};
      const { incentiveType, longName } = incentive || {};
      if (inputs?.length && !inputs?.includes(incentiveType)) {
        return;
      }
      incentives = {
        ...incentives,
        [item]: {
          ...incentive,
          label: i18n(`Incentives.types.${incentiveType}`, { REGION: longName }),
        },
      };
    }
  });
  const { taxCredit, ...rest } = incentives || {};
  const showTaxCredit = taxCredit && taxCredit?.period === 'taxCredit';

  return {
    ...(showTaxCredit ? { taxCredit } : {}),
    ...rest,
  };
};

export const getCurrentAvailableTotal = state => {
  const current = state?.Financial?.incentives?.currentAvailable || {};
  let total = {
    monthly: 0,
    once: 0,
    fuel: 0,
    taxCredit: 0,
    taxCreditLease: 0,
  };

  Object.keys(current).forEach(type => {
    const currentSelected = current?.[type];
    if (currentSelected?.length && !currentSelected[0]?.defaultUnselected) { // exclude default unselected incentives from available total
      const [incentive] = currentSelected || {};
      const { amount, period } = incentive || {};
      total = {
        ...total,
        [period]: (total[period] || 0) + amount,
      };
    }
  });

  return total;
};


export const getCurrentAvailableMonthlyFuel = state => {
  const { fuel } = state?.Financial?.incentives?.currentAvailable || {};
  const amount = fuel?.[0]?.amount || 0;
  const months = fuel?.[0]?.variables?.months || getFuelMonths(state);
  return Math.round(amount / months) || 0;
};

export const getSwapPrice = (state, vehicle) => {
  const showDriveAwayPrice = _get(state, 'ReviewDetails.showDriveAwayPrice');
  const showAmountIncludingFees = _get(state, 'FinancingOptions.showAmountIncludingFees', false);
  const showOrderFee = _get(state, 'FinancingOptions.showOrderFee', false);
  const registrationType = _get(state, 'ReviewDetails.RegistrationDetail.RegistrantType', '');
  const accountType = _get(state, 'ReviewDetails.AccountDetail.AccountType', '');
  const regionCode = _get(state, 'SummaryPanel.region_code', '');
  const optionCodeData = _get(vehicle, 'OptionCodeData', {});
  const optionCodeList = _get(vehicle, 'OptionCodeList', '');

  const customerType = registrationType || accountType;
  const calculatorState = {
    Incentives: state.Financial.fms_incentives,
    Fees: state.Financial.fms_fees,
    Loan: state.Financial.fms_loan,
    Lease: state.Financial.fms_lease,
    Lexicon: state.OMS.lexicon,
    Configuration: {
      options: optionCodeList?.split(','),
      UnselectedOptions: [],
    },
  };

  const priceParams = {
    ...state.OMS.oms_params,
    financeType: 'cash',
    financeProductId: 'cash',
    customerType,
    extraFeeAmount: getAddOnToGrossPrice(state),
    regionCode: regionCode,
    vehiclePrice: null, // This will trigger the price to be calculated based on options.
    deliveryFee: getDestinationAndDocFee(state),
    prevRegistered: false,
    newRegState: true,
    orderFee: showOrderFee ? getDepositAmount(state) : 0,
  };

  const optionsByGroup = _reduce(
    optionCodeData,
    (previousGroups, currentGroup) => {
      const group = currentGroup?.group;
      const code = currentGroup?.code;
      if (!group || !code) {
        return previousGroups;
      }
      return {
        ...previousGroups,
        [group]: previousGroups[group] ? previousGroups[group].concat(code) : [code],
      };
    },
    {}
  );

  const priceData = Calculator.total(calculatorState, {
    ...priceParams,
    optionsByGroup,
    isInventory: false,
    priceContext: _get(state, 'App.pricingContext'),
  });

  const orderedConfiguratorPrice =
    showDriveAwayPrice || showAmountIncludingFees ? priceData?.grossPrice : priceData?.total;
  return orderedConfiguratorPrice || 0;
};

export const getCombinedTaxesAndFees = (state) => {
  let combinedTaxesAndFees = 0;
  const taxesAndFees = getTaxesAndFees(state);
  if (taxesAndFees) {
    const { teslaFees = [], nonTeslaFees= [], taxes = [] } = taxesAndFees ?? {};
    combinedTaxesAndFees = [...nonTeslaFees, ...teslaFees, ...taxes].reduce((acc, item) => acc + (item.amount ?? 0), 0);
  }
  return combinedTaxesAndFees;
}

export const getTaxesAndFeesMonthly = state => {
  const total = getCombinedTaxesAndFees(state);
  const { termLength = 0 } = state?.Pricing.finplat?.output?.inputs ?? {};
  if (total && termLength) {
    return formatMonthlyPrice(Math.round(total / termLength));
  }
  return 0;
}

/**
 * Get Veterans Credit
 */
 export const getVeteranCredit = state => {
  return _get(state, 'Financial.incentives.current.veteranCredit[0].amount', 0);
};

export const getCaptionText = state => {
  const veterenCredit = getVeteranCredit(state);
  const referralCredit = getReferralCredit(state);
  const referralKey = referralCredit?.credit ? 'referral' : '';
  const veteranKey = veterenCredit ? 'veteran' : '';
  return i18n('Review.discount_included', {}, null,
    {
      specificOverride: [veteranKey, referralKey],
      specificOverrideOperator: 'OR',
      returnNullWhenEmpty: true,
    }
  );
};

export const showDefaultTrimForFinanceProduct = state => {
  const { Configuration, FinancingOptions } = state;
  const { option_codes = [] } = Configuration;
  const { defaultTrimForFinanceProduct: { selected_by } = {} } = FinancingOptions || {};
  if (selected_by) {
    return parseSelectedBy(selected_by, option_codes, [], state);
  }
};

/**
 * Gets the total est. CASH purchase price, which includes the following:
 *   Trim Price + Options
 *   All Fees (including order and transport)
 *   Purchase Price Incentive
 *   Cash Credit
 *   Reservation Credit
 * 
 * This method is orignially meant to be used in the Review Details section of the order page for est. purchase price
 * 
 * @return {number} estimated cash purchase price (without currency formatting)
 */
export const getPurchasePriceTotal = state => {
  const { orderPayment = 0, paymentSourceSubType = '' } = getDepositAmount(state, true) || {};
  const isOrderFee = paymentSourceSubType?.includes(ORDER_FEE_TYPE);
  const includeOrderFeeInVehicleTotal = _get(state, 'ReviewDetails.includeOrderFeeInVehicleTotal', false);
  const includeOrderFeeInVehicleTotalFlag = isOrderFee ? includeOrderFeeInVehicleTotal : false;
  const orderFeeAmount = getOrderFeeFromFinancialSvc(state) || (includeOrderFeeInVehicleTotalFlag ? orderPayment : 0);
  const creditCash = _get(state, 'ApplicationFlow.referral.cash.value', 0);
  const transportFeeAmount = getTransportationFeeAmount(state);
  const feesTotal = _get(state, 'Pricing.calculatorResult.data.apiResults.fees.total.once', 0);
  const purchasePriceIncentive = getPurchasePriceIncentive(state);
  const reservationCredit = getReservationCredit(state);
  const vehiclePrice = _get(state, 'Pricing.total', 0);
    
  return vehiclePrice +
    orderFeeAmount +
    feesTotal +
    transportFeeAmount +
    purchasePriceIncentive -
    creditCash +
    reservationCredit;
}
/**
 * Return government incentives
 */
 export function getAvailableGovernmentIncentives(state) {
  const incentives = _get(state, 'Financial.incentives.currentAvailable.government[0]', {});
  return incentives?.amount || 0;
}

/**
 * Return price postfix if defined
 */
export function getPricePostfix(state, postfixFallback = '') {
  const { App, Configuration, ReviewDetails } = state;
  const { pricePostfix, pricePostfixWithConditions } = App;
  if (pricePostfixWithConditions?.pricePostfix) {
    const { option_codes: optionCodes } = Configuration;
    const { params = {} } = pricePostfixWithConditions;
    const { showAfterSavingsPriceForTrims = false } = ReviewDetails || {};
    const meetsConditions = pricePostfixWithConditions?.conditions ? matchAgainstEveryKey(pricePostfixWithConditions?.conditions, { showAfterSavingsPriceForTrims }) : true;
    const isSelectedBy = pricePostfixWithConditions?.selected_by ? parseSelectedBy(pricePostfixWithConditions?.selected_by, optionCodes, [], state) : true;
    if (meetsConditions && isSelectedBy) {
      return pricePostfixWithConditions?.pricePostfix;
    } else {
      return '';
    }
  }
  return pricePostfix || postfixFallback;
}

export const getPricingSummary = state => {
  const { showAfterSavingsPriceForTrims = false } = state?.ReviewDetails || {};
  const { canModifyOrder: isEditDesign = false, optimizelyExpId = null } = state?.ApplicationFlow || {};
  const { taxCredit, governmentIncentive, hasNoConditions: hasNoConditionsInit, regionalIncentive } = getFinancialIncentives(state) || {};
  const isExpDisabled = (optimizelyExpId === EXPERIMENT_TOP_BANNER_OFF);
  const hasNoConditions = isExpDisabled ? false : hasNoConditionsInit;
  const { finplat } = state?.Pricing || {};
  const { inputs, outputs } = finplat?.output || {};
  const { termLength = 0 } = inputs ?? {};
  const finplatMonthly = outputs?.monthlyPaymentWithoutIncentives || outputs?.monthlyPayment || state?.Pricing?.lease?.monthlyPayment || 0;
  const price = finplatMonthly || getPurchasePriceTotal(state);
  const { isUsedInventory, data: inventoryData = {} } = state?.ReviewDetails?.product || {};
  const { FederalIncentives: { IsTaxIncentiveEligible = false } = {} } = inventoryData || {};
  const transportFee = isUsedInventory && IsTaxIncentiveEligible ? getTransportationFeeAmount(state) || 0 : 0;
  const baseIncentive = (!finplat ? taxCredit : 0) + governmentIncentive || 0;
  const incentive = hasNoConditions && regionalIncentive ? baseIncentive + regionalIncentive : baseIncentive;
  const monthlyPrice = (outputs?.monthlyPayment || 0) + (termLength ? (incentive / termLength) || 0: 0);
  const priceAfterCredits = Math.max(monthlyPrice || (price + incentive + transportFee) || 0, 0);
  let incentives = {
    price: priceAfterCredits,
    formattedPrice: monthlyPrice ? formatMonthlyPrice(priceAfterCredits) : formatCurrency(priceAfterCredits),
  };
  if (!isEditDesign && showAfterSavingsPriceForTrims) {
    if (taxCredit || (hasNoConditions && regionalIncentive)) {
      const federalKey = taxCredit ? 'federal' : '';
      const regionalKey = hasNoConditions && regionalIncentive ? 'regional' : '';
      const regionName = regionalKey ? (getUserRegionName(state) || getUserRegionCode(state)) : '';
      let incentiveKey = 'taxCredit';
      if (federalKey && regionalKey) {
        incentiveKey = 'taxCredit:regional';
      } else if (regionalKey) {
        incentiveKey = 'regional';
      }
      incentives = {
        ...incentives,
        label: i18n('Incentives.federalIncentive.savingsAfterCreditIfEligible', {
            REGION_NAME: regionName,
          }, null, {
          specificOverride: [federalKey, regionalKey],
          specificOverrideOperator: 'OR',
          returnNullWhenEmpty: true,
        }),
        key: incentiveKey,
      }
    }
    if (governmentIncentive) {
      incentives = {
        ...incentives,
        label: i18n('Incentives.savingsAfterGovtIfEligible'),
        key: 'government',
      };
    }
  }
  return {
    incentives,
    purchasePrice: price,
    isMonthly: !!finplatMonthly,
    formattedPurchasePrice: finplatMonthly ? formatMonthlyPrice(price) : formatCurrency(price),
  }
}